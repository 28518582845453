import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { GetProgramGroupsResponse, GetProgramGroupsRequest } from './types'
import { UnknownRequest } from '@/shared/swagger'

export default async function getProgramGroups (params: GetProgramGroupsRequest): Promise<AxiosResponse<GetProgramGroupsResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, GetProgramGroupsResponse>({
    url: 'service/program-groups',
    body: { params },
    loader: 'get-service/program-groups'
  })

  return response
}
