import type { StoreType } from '../types'
import { useAlert } from '@/shared/composable'
import { GetProgramGroupsRequest } from '../api/methods/getProgramGroups/types'
import getProgramGroups from '../api/methods/getProgramGroups'

export default async function fetchProgramGroups (this: StoreType, params: GetProgramGroupsRequest): Promise<void> {
  const { error } = useAlert()
  const { data, ok, message } = await getProgramGroups(params)

  if (ok) {
    this.programGroups = data
    this.programGroupsFiltered = data
  } else {
    error({ text: message, isToast: true })
  }
}
