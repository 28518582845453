import type { AxiosResponse } from '@shared/types'
import { useRequests } from '@/shared/composable'
import { UnknownRequest } from '@/shared/swagger'
import { GetMedicalCheckupsCardsRequest, GetMedicalCheckupsCardsResponse } from './types'

export default async function getCards (params: GetMedicalCheckupsCardsRequest): Promise<AxiosResponse<GetMedicalCheckupsCardsResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, GetMedicalCheckupsCardsResponse>({
    url: '/service/medical-checkups/card',
    body: { params },
    loader: '/service/medical-checkups/card'
  })

  return response
}
