import { currentDialog, isShowDialog } from '../consts'
import { EOutcomeDialogs } from '../enums'

export const showDialog = (dialog: EOutcomeDialogs) => {
  currentDialog.value = dialog
  isShowDialog.value = true
}

export const hideDialog = () => {
  currentDialog.value = null
  isShowDialog.value = false
}
