<template>
  <pms-smart-form
    :fields="formFields"
    @handleSubmit="onSubmit"
  >
    <template #formControls>
      <div class="d-flex justify-content-end mt-2">
        <pms-button
          type="submit"
          class="mt-2"
          :text="$t('common.save')"
        />
      </div>
    </template>
  </pms-smart-form>
</template>
<script setup lang="ts">
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { FormGroup } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAlert } from '@/shared/composable'
import { hideEditDialog } from '../../utils'

const store = useProgramGroupStore()
const { programGroup } = storeToRefs(store)
const { error } = useAlert()

const tempValues = computed(() => {
  return {
    description_kz: programGroup.value?.description?.description_kz,
    description_ru: programGroup.value?.description?.description_ru,
    description_en: programGroup.value?.description?.description_en
  }
})

const onSubmit = async ({ values }) => {
  if (!programGroup.value) return
  const res = await store.editProgramGroupDescription(programGroup.value.code, {
    description_en: values.description_en,
    description_kz: values.description_kz,
    description_ru: values.description_ru
  })
  if (res.status) {
    hideEditDialog()
    await store.fetchProgramGroup(programGroup.value.code)
  }
}

const { t } = useI18n()
const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        label: {
          text: t('domains.program-groups.tabs.description.fields.description-kz'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'description_kz',
        type: 'textarea',
        required: true,
        value: tempValues.value.description_kz
      },
      {
        label: {
          text: t('domains.program-groups.tabs.description.fields.description-ru'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'description_ru',
        type: 'textarea',
        required: true,
        value: tempValues.value.description_ru
      },
      {
        label: {
          text: t('domains.program-groups.tabs.description.fields.description-en'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'description_en',
        type: 'textarea',
        required: true,
        value: tempValues.value.description_en
      }
    ]
  }]
)
</script>
