<template>
  <!-- TODO надо бы вывести его весь компонент в shared -->
  <!-- Основной контейнер, где контролируем высоту и добавляем hover-события -->
  <div
    :class="props.containerClass + ' p-3 border border-2 rounded-1 '"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <div
      class="w-100"
      :style="hoverStyle"
    >
      <label>
        <strong class="text-muted">{{ props.title }}</strong>
      </label>
      <p>{{ props.value }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: ''
  },
  value: {
    type: String,
    required: true,
    default: ''
  },
  containerClass: {
    type: String,
    required: false,
    default: 'col-12'
  },
  /**
   * Включает или выключает режим "увеличения при наведении"
   */
  expandOnHover: {
    type: Boolean,
    required: false,
    default: true
  },
  /**
   * Начальная (максимальная) высота компонента до наведения
   */
  defaultHeight: {
    type: String,
    required: false,
    default: '120px'
  },
  /**
   * Длительность анимации в секундах
   */
  transitionDuration: {
    type: Number,
    required: false,
    default: 0.3
  },
  /**
   * Максимальная высота при "раскрытии" (в пикселях),
   * чтобы анимация работала предсказуемо
   */
  expandedMaxHeight: {
    type: String,
    required: false,
    default: '500px'
  },
  /**
   * Количество строк, которые показываются до наведения.
   * После наведения показывается весь текст.
   */
  linesToClamp: {
    type: Number,
    required: false,
    default: 3
  }
})

const isHover = ref(false)

/**
 * Обработчики наведения/ухода курсора
 */
function onMouseOver () {
  isHover.value = true
}

function onMouseLeave () {
  isHover.value = false
}

/**
 * Стиль содержимого рассчитывается динамически на основании пропсов и ховера
 */
const hoverStyle = computed(() => {
  // Если отключено "expandOnHover", ничего не обрезаем, пусть тянется
  if (!props.expandOnHover) {
    return {}
  }

  // Базовые стили для анимации высоты
  const style: Record<string, string> = {
    maxHeight: isHover.value ? props.expandedMaxHeight : props.defaultHeight,
    overflow: 'hidden',
    transition: `max-height ${props.transitionDuration}s ease-in-out`
  }

  // Если НЕ hover — обрезаем до N строк с помощью -webkit-line-clamp
  // При hover убираем эти ограничения, чтобы показать полный текст
  if (!isHover.value) {
    style.display = '-webkit-box'
    style.webkitBoxOrient = 'vertical'
    style.webkitLineClamp = String(props.linesToClamp)
    // text-overflow: ellipsis обычно работает для однострочного режима;
    // но вместе с -webkit-line-clamp часто оставляют для «корректного» обрезания
    style.textOverflow = 'ellipsis'
  }

  return style
})
</script>
