import type { NestedListItem } from '@shared/types'
import type { ResourceResponse } from '@shared/swagger'
import { navItemsToListItems } from '../../../utils'
import { AcademicManageCurriculumNavigationResponse } from '@/domains/AcademicCurricula/store/api/methods/getCurriculumNavigation/types'

export default (yearTerms: ResourceResponse['year_terms'], map: Map<number, AcademicManageCurriculumNavigationResponse>): Array<NestedListItem> => {
  const list: Array<NestedListItem> = []
  const years = [...new Set(yearTerms.map(({ year }) => year))]

  years.forEach((year) => {
    const mapItem = map.get(year)

    list.push({
      title: `${year}`,
      nestedItems: mapItem
        ? navItemsToListItems(mapItem)
        : [{ title: '---' }]
    })
  })

  return list
}
