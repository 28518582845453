import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useMedicalCheckupsStore = defineStore<Name, State, Getters, Actions>('MedicalCheckups', {
  actions: {
    ...actions
  },

  getters: {
    // use storeToRefs
  },

  state () {
    return {
      students: null,
      employees: null,
      cards: null,
      notConfirmedCards: null,
      view: 'students'
    }
  }
})
