import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { PutPartnerRequest } from './types'
import { UnknownResponse } from '@/shared/swagger'

export default async function putPartner (code: string, body: PutPartnerRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<PutPartnerRequest, UnknownResponse>({
    url: `service/program-groups/${code}/partner`,
    body,
    loader: 'put-service/program-groups/partner'
  })
  return response
}
