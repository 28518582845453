import type { AxiosResponse } from '@shared/types'
import { useRequests } from '@/shared/composable'
import { PutCheckupRequest, PutCheckupResponse } from './types'

export default async function putCheckup (body: PutCheckupRequest): Promise<AxiosResponse<PutCheckupResponse>> {
  const { put } = useRequests()

  const response = await put<PutCheckupRequest, PutCheckupResponse>({
    url: '/service/medical-checkups',
    body: body,
    loader: '/service/medical-checkups'
  })

  return response
}
