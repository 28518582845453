import type { StoreType } from '../types'
import { useAlert } from '@/shared/composable'
import { PostProgramGroupRequest } from '../api/methods/postProgramGroup/types'
import PostProgramGroup from '../api/methods/postProgramGroup'
import { StatusMessageResponse } from '@/shared/types'

export default async function createProgramGroup (this: StoreType, params: PostProgramGroupRequest): Promise<StatusMessageResponse> {
  const { error, success } = useAlert()
  const { ok, message } = await PostProgramGroup(params)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
