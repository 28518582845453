import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { GetProgramGroupOutcomeRequest, GetProgramGroupOutcomesResponse } from './types'
import { UnknownRequest } from '@/shared/swagger'

export default async function getProgramGroupOutcomes (code: string, params: GetProgramGroupOutcomeRequest): Promise<AxiosResponse<GetProgramGroupOutcomesResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, GetProgramGroupOutcomesResponse>({
    url: `service/program-groups/${code}/outcomes`,
    body: {
      params
    },
    loader: 'get-service/program-groups/outcomes'
  })
  return response
}
