import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { UnknownRequest, UnknownResponse } from '@/shared/swagger'

export default async function deleteProgramGroupPartner (code: string): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<UnknownRequest, UnknownResponse>({
    url: `service/program-groups/${code}/partner`,
    body: { },
    loader: 'destroy-service/program-group/partner'
  })

  return response
}
