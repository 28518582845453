<template>
  <pms-card
    class="mb-2 overflow-hidden"
    :header="false"
    :stretch="100"
  >
    <div class="position-relative">
      <div class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
        <span
          class="pinned text-muted position-relative"
          style="font-size: 50vh; opacity: 0.2;"
        >
          {{ term.term }}
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span>
          <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
            <pms-button
              class="mb-4"
              :variant="'outlined'"
              :size="'sm'"
              @Click="handleEditTerm(props.term.term)"
            >
              <i class="bi bi-pencil-fill" />
              {{ t('domains.academic.curricula.view.edit-term-button') }}
            </pms-button>
          </PermissionCheck>
        </span>
      </div>
      <pms-smart-table
        :headers="headers"
        :items="term.courses"
        style="padding-bottom: 45px;"
      >
        <template
          v-if="props.isReferenceToolsActive"
          #checkbox="{ item }"
        >
          <div>
            <input
              v-if="!item.is_slot && item.references.length === 0"
              type="checkbox"
              class="form-check-input"
              :value="props.checkedCoreCourses[item.id]"
              @change="handleCheckCoreCourse(item.id, $event)"
            >
          </div>
        </template>
        <template #theory="{ value }">
          {{ value }}
        </template>
        <template #practice="{ item }">
          {{ item.practice }} + {{ item.lab }}
        </template>
        <template #requisites="{ item }">
          <template v-if="item.type=='C'">
            <div class="d-flex gap-1 flex-wrap">
              <pms-button
                v-for="(requisite, index) in item.requisites"
                :key="index"
                :size="'sm'"
                :variant="'outlined'"
                @Click="handleClickRequisite(requisite)"
              >
                {{ (requisite as typeof term['courses'][0]['requisites'][0]).type }}
              </pms-button>
              <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-plus-circle" />
                  </button>
                  <ul
                    class="dropdown-menu w-auto"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <template v-for="(requisiteType, index) in requisiteTypes">
                      <li
                        v-if="!courseHasRequisite(item, requisiteType)"
                        :key="item.id + index"
                        class="cursor-pointer"
                        @click="handleAddRequisite(item, requisiteType)"
                      >
                        <span
                          class="dropdown-item text-center"
                        >
                          <i class="bi bi-plus-circle" /> {{ requisiteType }}
                        </span>
                      </li>
                    </template>
                  </ul>
                </div>
              </PermissionCheck>
            </div>
          </template>
        </template>
        <template #code="{item}">
          <pms-dropdown
            v-if="userStore.hasPermissionByModuleCode(permissionCodes.edit_curricula.code)"
            :btn-class="'btn btn-light-primary btn-sm p-2 px-4'"
            :placement="'start'"
            :variant="'tonal'"
            :show-icon="false"
          >
            <template #button>
              <pms-button
                :text="item.code"
                :size="'sm'"
                class="btn btn-outline btn-outline-primary"
              />
            </template>
            <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
              <div class="d-flex gap-2 flex-wrap">
                <pms-button
                  v-if="item.is_slot !== true"
                  :size="'sm'"
                  class="text-nowrap w-150px"
                  @Click="handleEditCourse(item)"
                >
                  {{ t('domains.academic.curricula.view.edit-course-button') }}
                </pms-button>
                <pms-button
                  :size="'sm'"
                  class="text-nowrap w-150px"
                  @Click="handleEditCurriculaSettings({id: item.id, is_slot: item.is_slot, trackId: trackId})"
                >
                  {{ t('domains.academic.curricula.view.course-settings-button') }}
                </pms-button>
              </div>
            </PermissionCheck>
          </pms-dropdown>
          <span v-else>{{ item.code }}</span>
        </template>
      </pms-smart-table>
      <pms-smart-table
        class="mt-5"
        :headers="totalHeaders"
        :items="term.total"
      >
        <template #title="{value}">
          <span class="fw-bold">
            {{ $t(value) }}
          </span>
        </template>
      </pms-smart-table>
    </div>
  </pms-card>
</template>
<script lang="ts" setup>
import { coreHeaders as headers, totalHeaders } from '../../CurriculaViewPage/values'
import { TotalCourse } from '../types'
import { computed, PropType } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useCourseEditor from '../../../shared/composable/useCourseEditor'
import { useStore } from '../../../store'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useUser } from '@/shared/stores'
import permissionCodes from '@/shared/values/permissionCodes'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { AcademicManageCurriculumViewResponse } from '@/domains/AcademicCurricula/store/api/methods/getCurriculumView/types'

const route = useRoute()
const router = useRouter()
const courseEditor = useCourseEditor()
const handleEditCurriculaSettings = courseEditor.handleEditCourse
const store = useStore()
const userStore = useUser()
const { curricula } = storeToRefs(store)
const { t } = useI18n()

const requisiteTypes = ['AR', 'PR', 'CR']
type TCourse = (AcademicManageCurriculumViewResponse['groups'][0])['items'][0]

const courseHasRequisite = (course: TCourse, requisiteType: string) => {
  for (let i = 0; i < course.requisites.length; i++) {
    if (course.requisites[i].type === requisiteType) {
      return true // Найден соответствующий реквизит
    }
  }

  return false // Реквизит не найден
}

const track_code = computed(() => curricula.value?.information.program.tracks.find(track => track.id === curricula.value?.information.track.id))

const handleAddRequisite = (course: TCourse, requisiteType: string) => {
  if (!curricula.value || !userStore.hasPermissionByModuleCode(permissionCodes.edit_curricula.code)) return
  router.push({
    name: 'AcademicCoursesManageRequisitePage',
    params: {
      code: course.code,
      year: curricula.value.information.program.year,
      requisite_type: requisiteType
    },
    query: {
      program_code: curricula.value.information.program.code,
      track: track_code.value?.code
    }
  })
}

const handleEditCourse = (item: typeof props.term.courses[0]) => {
  if (!curricula.value) return
  router.push({
    name: 'AcademicManageCoursesEditPage',
    params: {
      code: item.code,
      year: curricula.value.information.program.year
    },
    query: {
      program_code: curricula.value.information.program.code,
      track: track_code.value?.code
    }
  })
}

const handleCheckCoreCourse = (id: number, event: Event) => {
  // Приведение типа event.target к HTMLInputElement для доступа к свойству checked
  const target = event.target as HTMLInputElement
  const newValue = target.checked

  props.checkedCoreCourses[id] = newValue
}

const handleEditTerm = (term: number) => {
  if (typeof route.query.track === 'string') {
    router.push({
      name: 'EditCurriculumTermPage',
      params: {
        trackId: route.query.track,
        term
      }
    })
  }
}

const handleClickRequisite = (requisite: typeof props.term['courses'][0]['requisites'][0]) => {
  if (!curricula.value || !userStore.hasPermissionByModuleCode(permissionCodes.edit_curricula.code)) return
  router.push({
    name: 'AcademicCoursesManageRequisitePage',
    params: {
      code: requisite.course_code,
      year: requisite.year,
      requisite_type: requisite.type
    },
    query: {
      program_code: curricula.value.information.program.code,
      track: track_code.value?.code
    }
  })
}

const props = defineProps({
  term: {
    type: Object as PropType<{
      term: number;
      courses: (AcademicManageCurriculumViewResponse['groups'][0])['items'];
      total: Array<TotalCourse>;
    }>,
    required: true
  },
  trackId: {
    type: Object as PropType<number>,
    required: true
  },
  isReferenceToolsActive: {
    type: Object as PropType<boolean>,
    required: true
  },
  checkedCoreCourses: {
    type: Object as PropType<Record<string, boolean>>,
    required: true
  }
})
</script>
