<template>
  <pms-button
    class="mt-2"
    :text="$t('common.add')"
    :onclick="onClick"
  />
</template>
<script lang="ts" setup>
import { EOutcomeDialogs } from '../../enums'
import { showDialog } from '../../utils'

const onClick = () => {
  showDialog(EOutcomeDialogs.AddOutcome)
}
</script>
