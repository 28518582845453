import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { PostProgramGroupRequest } from './types'
import { UnknownRequest, UnknownResponse } from '@/shared/swagger'

export default async function PostProgramGroup (body: PostProgramGroupRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<UnknownRequest, UnknownResponse>({
    url: 'service/program-groups',
    body,
    loader: 'post-service/program-groups'
  })
  return response
}
