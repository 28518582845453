<template>
  <div class="form-group">
    <label
      for="programGroup-select"
      class="form-label"
    >{{ $t('domains.program-groups.parent-code') }}:</label>
    <TreeSelect
      v-model="selectedProgramGroup"
      v-model:expanded-keys="expandedKeys"
      :options="treeOptions"
      :placeholder="$t('common.common.not-selected')"
      :panel-class="'custom-tree-panel'"
      class="form-control"
      input-id="programGroup-select"
      filter
      @node-expand="onNodeExpand"
      @node-collapse="onNodeCollapse"
      @update:model-value="onProgramGroupChange"
    >
      <!-- Кастомный слот для рендера узлов -->
      <template #option="{ node }">
        <span
          class="tree-node-label"
          :class="{ 'not-selectable': !node.selectable }"
          @click="toggleNodeExpansion(node.key)"
        >
          {{ node.label }}
        </span>
      </template>
    </TreeSelect>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineModel } from 'vue'
import TreeSelect from 'primevue/treeselect'
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { GetProgramGroupsResponse } from '@/domains/AcademicProgramGroups/store/api/methods/getProgramGroups/types'

const store = useProgramGroupStore()
const { programGroups, programGroup } = storeToRefs(store)

const generateDefaultValue = (value: number) => {
  const a = {}
  a[`${value}`] = true
  return a
}

const model = defineModel()

const selectedProgramGroup = computed(() => {
  return model.value ? generateDefaultValue(model.value) : null
})

// Обработчик изменения выбранного значения
const onProgramGroupChange = (newValue: any | null) => {
  if (!newValue) {
    model.value = null
  }
  const programGroupKey = Object.keys(newValue)[0]

  selectedProgramGroup.value = newValue
  if (model && newValue) {
    model.value = programGroupKey
  } else {
    model.value = null
  }
}

export type TreeNode<T> = {
  key: string;
  label: string;
  children?: TreeNode<T>[]; // Рекурсивное вложение для дочерних узлов
  item: T; // Исходный элемент данных
};

function transformToTree (
  data: GetProgramGroupsResponse | undefined, // Входной массив
  parentCode: string | null = null
): TreeNode<GetProgramGroupsResponse[number]>[] | undefined { // Возвращаемый тип
  return data
    ? data
      .filter(item => item.parent_code === parentCode)
      .map(item => ({
        key: item.code,
        label: `${item.code} - ` + item.title_en,
        children: transformToTree(data, item.code), // Рекурсия
        selectable: programGroup.value?.code !== item.code,
        item // Исходный элемент
      }))
    : undefined
}

// Преобразование данных в формат TreeSelect
const treeOptions = computed(() => {
  const res = programGroups.value ? transformToTree(programGroups.value) : []
  return res
})

// Раскрытые узлы
const expandedKeys = ref<Record<string, boolean>>({})

// Переключение состояния раскрытия узла
const toggleNodeExpansion = (key: string) => {
  if (expandedKeys.value[key]) {
    delete expandedKeys.value[key]
  } else {
    expandedKeys.value[key] = true
  }
}

// Обработчик события раскрытия узла
const onNodeExpand = (node: TreeNode<GetProgramGroupsResponse[number]>) => {
  expandedKeys.value[node.key] = true
}

// Обработчик события сворачивания узла
const onNodeCollapse = (node: TreeNode<GetProgramGroupsResponse[number]>) => {
  delete expandedKeys.value[node.key]
}
</script>

<style scoped>
/* Стили для панели TreeSelect */
.custom-tree-panel {
  max-height: 300px;
  overflow-y: auto;
}

/* Стили для интеграции с Bootstrap */
.form-control {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #212529;
}

.tree-node-label {
  cursor: pointer;
}

.not-selectable {
  color: gray;
  cursor: default;
}
</style>
