<template>
  <pms-smart-form
    :fields="formFields"
    @handleSubmit="onSubmit"
  >
    <template #institution_id>
      <UniversitySelectField v-model="tempValues.institution_id" />
    </template>
    <template #formControls>
      <div class="d-flex justify-content-end mt-2">
        <pms-button
          type="submit"
          class="mt-2"
          :text="$t('common.save')"
        />
      </div>
    </template>
  </pms-smart-form>
</template>
<script setup lang="ts">
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { FormGroup } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import UniversitySelectField from './ui/UniversitySelectField.vue'
import { useAlert } from '@/shared/composable'
import { hideEditDialog } from '../../utils'

const store = useProgramGroupStore()
const { programGroup } = storeToRefs(store)
const { error } = useAlert()

const tempValues = computed(() => {
  const partner = programGroup.value ? programGroup.value.partner : null
  return {
    institution_id: partner?.institution_id ?? undefined,
    agreement_number: partner?.agreement_number ?? undefined,
    start_date: partner?.start_date ?? undefined,
    end_date: partner?.end_date ?? undefined
  }
})

const onSubmit = async ({ values }) => {
  if (!tempValues.value.institution_id || !values.agreement_number || !values.start_date) {
    error({ text: 'Fill required fields T' })
    return
  }

  if (!programGroup.value) return
  const res = await store.editProgramPartner(programGroup.value.code, {
    institution_id: tempValues.value.institution_id,
    agreement_number: values.agreement_number,
    start_date: values.start_date,
    end_date: values.end_date
  })
  if (res.status) {
    hideEditDialog()
    await store.fetchProgramGroup(programGroup.value.code)
  }
}

const { t } = useI18n()
const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        label: {
          text: t('domains.program-groups.tabs.partner.fields.partner-university'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'institution_id',
        type: 'slot',
        value: tempValues.value.institution_id
      },
      {
        label: {
          text: t('domains.program-groups.tabs.partner.fields.start-date'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'start_date',
        type: 'date',
        required: true,
        value: tempValues.value.start_date
      },
      {
        label: {
          text: t('domains.program-groups.tabs.partner.fields.end-date'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'end_date',
        type: 'date',
        value: tempValues.value.end_date
      },
      {
        label: {
          text: t('domains.program-groups.tabs.partner.fields.agreement-number'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'agreement_number',
        type: 'text',
        required: true,
        value: tempValues.value.agreement_number
      }
    ]
  }]
)
</script>
