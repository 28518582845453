import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { PutProgramGroupDescriptionRequest } from './types'
import { UnknownResponse } from '@/shared/swagger'

export default async function putProgramGroupDescription (code: string, body: PutProgramGroupDescriptionRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<PutProgramGroupDescriptionRequest, UnknownResponse>({
    url: `service/program-groups/${code}/description`,
    body,
    loader: 'put-service/program-groups/description'
  })
  return response
}
