<template>
  <div>
    <pms-page-shimmer
      v-if="loadProgramGroups"
      :shimmer="`list`"
      :full-width="true"
    />
    <Tree
      v-else
      v-model:expandedKeys="expandedKeys"
      :value="treeData"
      selection-mode="single"
      :selection-keys.sync="selectedKeys"
      :filter="true"
      :filter-placeholder="$t('domains.program-groups.filter.program-group-placeholder')"
    >
      <template #default="{ node }">
        <div class="tree-node d-flex gap-5">
          <span @click="toggleExpand(node)">{{ node.label }}</span>
          <div class="actions d-flex gap-3">
            <i
              v-tooltip.top="'Show'"
              class="bi bi-card-heading"
              @click="handleOpen(node.item.code)"
            />
            <i
              v-tooltip.top="'Add child'"
              class="bi bi-plus-lg text-primary"
              @click="handleAdd(node)"
            />
            <i
              v-tooltip.top="'Delete'"
              class="bi bi-trash text-danger"
              @click="onPressDelete(node)"
            />
          </div>
        </div>
      </template>
    </Tree>
    <Dialog
      v-model:visible="isShowDialog"
      modal
    >
      <ProgramGroupCreate />
    </Dialog>
  </div>
</template>
<script setup lang="ts">
import Tree from 'primevue/tree'
import useProgramTreeModel from './composable/useProgramTreeModel'
import Dialog from 'primevue/dialog'
import ProgramGroupCreate from '@/domains/AcademicProgramGroups/ui/ProgramGroupCreateForm/ProgramGroupCreateForm.vue'
import { isShowDialog } from '@/domains/AcademicProgramGroups/consts'
import { useRouter } from 'vue-router'
import { useAlert, useLoader } from '@/shared/composable'
import { useI18n } from 'vue-i18n'

const { dialog } = useAlert()

const { isActive } = useLoader()
const loadProgramGroups = isActive('get-service/program-groups')
const router = useRouter()
const { t } = useI18n()

const handleOpen = (code: string) => {
  router.push({
    name: 'AcademicProgramGroupPage',
    params: {
      code
    }
  })
}

const onPressDelete = (node) => {
  dialog({
    text: t('phrases.are-you-sure'),
    onConfirm: () => {
      handleDelete(node)
    }
  })
}

const { handleAdd, handleDelete, toggleExpand, selectedKeys, treeData, expandedKeys } = useProgramTreeModel()
</script>
