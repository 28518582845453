export const views = [
  {
    id: 'students',
    image: '/media/icons/duotune/technology/teh002.svg',
    title: 'domains.medical-checkups.student',
    description: 'domains.medical-checkups.student-description'
  },
  {
    id: 'employees',
    image: '/media/icons/duotune/communication/com005.svg',
    title: 'domains.medical-checkups.employee',
    description: 'domains.medical-checkups.employee-description'
  },
  {
    id: 'cards',
    image: '/media/icons/duotune/general/gen005.svg',
    title: 'domains.medical-checkups.card',
    description: 'domains.medical-checkups.card-description'
  }
]
