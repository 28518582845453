<template>
  <div class="d-flex justify-content-end">
    <pms-button
      class="mt-2"
      :text="$t('common.add')"
      :size="'md'"
      @click="handleOnClick"
    />
  </div>
</template>
<script lang="ts" setup>
import { showDialog } from '../../utils'
import { focusedProgramGroupInList } from '../ProgramTree/consts'

const handleOnClick = () => {
  focusedProgramGroupInList.value = null
  showDialog()
}
</script>
<style scoped>
button {
  width: 100px;
}
</style>
