<template>
  <pms-smart-form
    :fields="formFields"
    @handleSubmit="onSubmit"
  >
    <template #formControls>
      <div class="d-flex justify-content-end mt-2">
        <pms-button
          type="submit"
          class="mt-2"
          :text="$t('common.save')"
        />
      </div>
    </template>
  </pms-smart-form>
</template>
<script lang="ts" setup>
import { FormGroup } from '@/shared/types'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useProgramGroupStore } from '@domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { hideDialog } from '@/domains/AcademicProgramGroups/features/AcademicProgramGroup/ui/OutcomeInfo/utils'

const { t } = useI18n()

const store = useProgramGroupStore()
const { resources, programGroup } = storeToRefs(store)

const tempValues = ref({
  outcome_kz: '',
  outcome_ru: '',
  outcome_en: '',
  user_type: undefined,
  key: ''
})

const onSubmit = async ({ values }) => {
  if (!programGroup.value) return
  const res = await store.createProgramGroupOutcome(programGroup.value.code, { ...values })
  if (res.status) {
    hideDialog()
    store.fetchProgramGroupOutcomes(programGroup.value.code, { })
  }
}

const outcomeTypeOptions = computed(() => {
  const outcomeTypes = resources.value ? resources.value.user_types : []
  return outcomeTypes.map((i) => {
    return {
      label: i,
      value: i
    }
  })
})

const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        label: {
          text: t('domains.program-groups.tabs.outcome.fields.type'),
          class: 'mb-1 mt-2 required'
        },
        group: {
          class: 'col-12'
        },
        name: 'user_type',
        type: 'select',
        options: outcomeTypeOptions.value,
        required: true,
        value: tempValues.value.user_type
      },
      {
        label: {
          text: t('domains.program-groups.tabs.outcome.fields.key'),
          class: 'mb-1 mt-2 required'
        },
        group: {
          class: 'col-12'
        },
        name: 'key',
        type: 'text',
        required: true,
        value: tempValues.value.key
      },
      {
        label: {
          text: t('domains.program-groups.tabs.outcome.fields.outcome-kz'),
          class: 'mb-1 mt-2 required'
        },
        group: {
          class: 'col-12'
        },
        name: 'outcome_kz',
        type: 'textarea',
        required: true,
        value: tempValues.value.outcome_kz
      },
      {
        label: {
          text: t('domains.program-groups.tabs.outcome.fields.outcome-ru'),
          class: 'mb-1 mt-2 required'
        },
        group: {
          class: 'col-12'
        },
        name: 'outcome_ru',
        type: 'textarea',
        required: true,
        value: tempValues.value.outcome_ru
      },
      {
        label: {
          text: t('domains.program-groups.tabs.outcome.fields.outcome-en'),
          class: 'mb-1 mt-2 required'
        },
        group: {
          class: 'col-12'
        },
        name: 'outcome_en',
        type: 'textarea',
        required: true,
        value: tempValues.value.outcome_en
      }
    ]
  }]
)
</script>
