export enum ProgramGroupType {
  CIPHER = 'CIPHER',
  GROUP = 'GROUP',
  PROFESSION_TYPE = 'PROFESSION_TYPE',
  TRAINING_DIRECTION = 'TRAINING_DIRECTION',
}

export enum EOutcomeTypes {
  Program = 'P',
  Student = 'S'
}
