import { SmartTableHeader } from '@/shared/types'

export const studentsHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'w-50px'
  },
  {
    title: 'common.table.id',
    field: 'student_id',
    cellClass: 'fw-bold'
  },
  {
    title: 'common.table.full_name',
    field: 'full_name'
  },
  {
    title: 'common.table.checkup_date',
    field: 'checkup_date',
    slotCellName: 'checkup_date'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const employeesHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'w-50px'
  },
  {
    title: 'common.table.full_name',
    field: 'full_name',
    cellClass: 'fw-bold'
  },
  {
    title: 'common.table.position',
    field: 'position',
    slotCellName: 'position'
  },
  {
    title: 'common.table.checkup_date',
    field: 'checkup_date',
    slotCellName: 'checkup_date'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const cardsHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'w-50px'
  },
  {
    title: 'common.table.full_name',
    field: 'full_name',
    slotCellName: 'full_name'
  },
  {
    title: 'common.table.info',
    field: 'info',
    slotCellName: 'info'
  },
  {
    title: 'common.table.checkup_date',
    field: 'checkup_date',
    slotCellName: 'checkup_date'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const checkupsHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.checkup_date',
    field: 'checkup_date',
    slotCellName: 'checkup_date',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const notConfirmedCardsHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.id',
    field: 'user_id',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.checkup_date',
    field: 'checkup_date',
    cellClass: 'text-center'
  }
]
