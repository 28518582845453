<template>
  <pms-smart-form
    :fields="formFields"
    @handleSubmit="onSubmit"
    @handleChange="onHandleChange"
  />
</template>
<script lang="ts" setup>
import { FormGroup } from '@/shared/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useProgramGroupStore } from '@domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { hideDialog } from '../../utils'
import { focusedProgramGroupInList } from '../ProgramTree/consts'
import { programGroupCodeMask } from '../../consts'
import { programGroupsFilterValues } from '../ProgramFilter/consts'
import { filterTree } from '../ProgramFilter/utils'
import { ProgramGroupType } from '../../store/enums'

const store = useProgramGroupStore()
const { resources, programGroups } = storeToRefs(store)

const tempValues = ref({
  title_kz: '',
  title_en: '',
  title_ru: '',
  education_level: '',
  program_group_type: '',
  parent_code: focusedProgramGroupInList.value?.key,
  code: '',
  program_type: ''
})

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const { t } = useI18n()

const isSelectedProgramGroupTypeCipher = computed(() => {
  return tempValues.value.program_group_type === ProgramGroupType.CIPHER
})

const onSubmit = async ({ values }) => {
  const payload = {
    title_kz: values.title_kz,
    title_en: values.title_en,
    title_ru: values.title_ru,
    education_level: values.education_level,
    program_group_type: values.program_group_type,
    program_type: isSelectedProgramGroupTypeCipher.value ? values.program_type : undefined,
    parent_code: values.parent_code,
    code: values.code
  }
  const result = await store.createProgramGroup(payload)

  if (result.status) {
    hideDialog()
    await store.fetchProgramGroups({ })
    if (programGroups.value) {
      store.programGroupsFiltered = filterTree(programGroups.value, programGroupsFilterValues.value)
    }
  }
}

const educationLevelOptions = computed(() => {
  if (!resources.value) return []
  return resources.value.education_levels.map((educationLevel) => {
    return {
      label: educationLevel.title,
      value: educationLevel.code
    }
  })
})

// program_group_types
const programGroupTypeOptions = computed(() => {
  if (!resources.value) return []
  return resources.value.program_group_types.map((program_group_type) => {
    return {
      label: program_group_type,
      value: program_group_type
    }
  })
})

const programTypeOptions = computed(() => {
  if (!resources.value) return []
  return resources.value.degree_types.map((program_type) => {
    return {
      label: program_type.title,
      value: program_type.type
    }
  })
})

const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        label: {
          text: t('domains.program-groups.program-group-type'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'program_group_type',
        options: programGroupTypeOptions.value,
        type: 'select',
        value: tempValues.value.program_group_type
      },
      {
        label: {
          text: t('domains.program-groups.education-level'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'education_level',
        options: educationLevelOptions.value,
        type: 'select',
        value: tempValues.value.education_level
      },

      {
        label: {
          text: t('domains.program-groups.parent-code'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        disabled: true,
        name: 'parent_code',
        type: 'text',
        value: tempValues.value.parent_code,
        vMask: programGroupCodeMask
      },

      {
        label: {
          text: t('domains.program-groups.code'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'code',
        type: 'text',
        value: tempValues.value.code,
        vMask: programGroupCodeMask
      },

      {
        label: {
          text: t('domains.program-groups.title-kz'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'title_kz',
        type: 'textarea',
        value: tempValues.value.title_kz
      },
      {
        label: {
          text: t('domains.program-groups.title-ru'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'title_ru',
        type: 'textarea',
        value: tempValues.value.title_ru
      },
      {
        label: {
          text: t('domains.program-groups.title-en'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'title_en',
        type: 'textarea',
        value: tempValues.value.title_en
      },
      {
        label: {
          text: t('domains.program-groups.program-type'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'program_type',
        type: 'select',
        hide: !isSelectedProgramGroupTypeCipher.value,
        value: tempValues.value.program_type,
        options: programTypeOptions.value
      }
    ]
  }]
)
</script>
