import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { PostProgramGroupOutcomeRequest } from './types'
import { UnknownResponse } from '@/shared/swagger'

export default async function postProgramGroupOutcome (code: string, body: PostProgramGroupOutcomeRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<PostProgramGroupOutcomeRequest, UnknownResponse>({
    url: `service/program-groups/${code}/outcomes`,
    body,
    loader: 'post-service/program-groups/outcomes'
  })
  return response
}
