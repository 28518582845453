import { useAlert } from '@/shared/composable'
import { StoreType } from '../types'
import putProgramGroup from '../api/methods/putProgramGroup'
import { PutProgramGroupRequest } from '../api/methods/putProgramGroup/types'

export default async function editProgramGroup (this: StoreType, code: string, params: PutProgramGroupRequest) {
  const { error, success } = useAlert()
  const { ok, message } = await putProgramGroup(code, params)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
