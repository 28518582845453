import getStudents from '../api/methods/getStudents'
import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { GetMedicalCheckupsStudentsRequest } from '../api/methods/getStudents/types'
import { useAlert } from '@/shared/composable'

export default async function fetchStudents (this: StoreType, params: GetMedicalCheckupsStudentsRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getStudents(params)

  const { error } = useAlert()

  if (ok) {
    this.students = data
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
