import { useAlert } from '@/shared/composable'
import { StoreType } from '../types'
import deleteProgramGroupOutcome from '../api/methods/deleteProgramGroupOutcome'

export default async function fetchProgramGroupOutcome (this: StoreType, code: string, outcomeId: number) {
  const { error, success } = useAlert()
  const { ok, message } = await deleteProgramGroupOutcome(code, outcomeId)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
