<template>
  <Card
    class="mt-5"
  >
    <template #title>
      {{ $t('common.common.filter') }}
    </template>
    <template #content>
      <pms-smart-form
        :fields="formFields"
        @handleChange="onHandleChange"
      >
        <template #formControls />
      </pms-smart-form>
    </template>
  </Card>
</template>
<script setup lang="ts">
import useProgramFilterModel from './composable/useProgramFilterModel'
import Card from 'primevue/card'

const { formFields, onHandleChange } = useProgramFilterModel()
</script>
