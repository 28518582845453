<template>
  <pms-smart-form
    :fields="formFields"
    @handleChange="onHandleChange"
    @handleSubmit="onSubmitForm"
  >
    <template #formControls>
      <div class="d-flex gap-3 flex-end mt-6">
        <pms-button
          v-if="view === ViewValues.STUDENTS"
          variant="outlined"
          color="success"
          class="align-self-end"
          @click="uploadExcelModalVisible = true"
        >
          <i class="bi bi-file-earmark-excel-fill color-success" />
          {{ $t( 'domains.services.medical_checkups.upload_excel' ) }}
        </pms-button>
        <pms-button
          :type="'submit'"
          :disabled="loader"
          :loader="loader"
          :text="$t('common.search')"
          :searchable="true"
        />
      </div>
    </template>
  </pms-smart-form>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import { useMedicalCheckupsStore } from '../store'
import { useLoader, useAlert } from '@/shared/composable'
import { cardsValues, employeesValues, studentsValues, useForms, ViewValues, uploadExcelModalVisible } from '../values'
import { FormGroup } from '@/shared/types'
import { storeToRefs } from 'pinia'

const { isActiveOneOf } = useLoader()
const loader = isActiveOneOf(['/service/medical-checkups/card', '/service/medical-checkups/employee', '/service/medical-checkups/student'])
const store = useMedicalCheckupsStore()
const { error } = useAlert()
const { studentsFormFields, employeesFormFields, cardsFormFields } = useForms()
const values = ref<any>(studentsValues.value)
const formFields = ref<Array<FormGroup>>(studentsFormFields.value)
const { view } = storeToRefs(store)

const onSubmitForm = async ({ values }) => {
  const filteredValues = {
    name: values.name === '' ? null : values.name,
    surname: values.surname === '' ? null : values.surname,
    student_id: values.student_id === '' ? null : values.student_id,
    card_id: values.card_id === '' ? null : values.card_id
  }

  try {
    switch (store.view) {
      case ViewValues.STUDENTS:
        studentsValues.value = { ...filteredValues }
        await store.fetchStudents({ ...filteredValues })
        break
      case ViewValues.EMPLOYEES:
        employeesValues.value = { ...filteredValues }
        await store.fetchEmployees({ ...filteredValues })
        break
      case ViewValues.CARDS:
        cardsValues.value = { ...filteredValues }
        await store.fetchCards({ ...filteredValues })
        break
    }
  } catch (e) {
    error({ text: 'domains.administrative.gate-entry.search.error.records' })
  }
}

const onHandleChange = ({ name, newValue }) => {
  values.value[name] = newValue
}

watch(() => store.view, (newVal) => {
  switch (newVal) {
    case ViewValues.STUDENTS:
      formFields.value = studentsFormFields.value
      values.value = studentsValues.value
      break
    case ViewValues.EMPLOYEES:
      formFields.value = employeesFormFields.value
      values.value = employeesFormFields.value
      break
    case ViewValues.CARDS:
      formFields.value = cardsFormFields.value
      values.value = cardsValues.value
      break
  }
})
</script>
