import { AcademicInformationSchedulesResourcesResponse } from '@/shared/swagger'
import { MappedSpecialities } from '../types'

export default (data: AcademicInformationSchedulesResourcesResponse['buildings']): MappedSpecialities[] => {
  const options: MappedSpecialities[] = []
  const filterData = data.filter((el) => el.rooms.length > 0)

  filterData.forEach((el) => {
    const newObj = { id: el.id.toString(), label: el.title, data: el.id, children: [] as MappedSpecialities[] }
    if (el.rooms) {
      newObj.children = el.rooms.map((spec) => ({
        id: spec.id.toString(),
        label: spec.title,
        data: spec.title
      }))
    }
    options.push(newObj)
  })
  return options
}
