import { utils, writeFile } from 'xlsx'

export const downloadTemplate = () => {
  const wb = utils.book_new()

  const ws = utils.aoa_to_sheet([['id', 'date']])

  ws['!cols'] = [{ wch: 20 }, { wch: 30 }]

  utils.book_append_sheet(wb, ws, 'Exam Dates')
  writeFile(wb, 'MedicalCheckup.xlsx')
}
