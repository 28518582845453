<template>
  <div class="d-flex justify-content-end align-items-center mb-5">
    <i
      class="bi bi-pencil-square fs-2 ms-5"
      @click="showEditDialog()"
    />

    <i
      v-if="partner"
      class="bi bi-trash fs-2 ms-5 text-danger"
      @click="handleDelete"
    />
  </div>
  <div v-if="partner">
    <div class="container">
      <div class="row g-3">
        <FieldSet
          :title="$t('domains.program-groups.tabs.partner.fields.country')"
          :value="country?.country"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.partner.fields.partner-university')"
          :value="partnerUniversity?.title"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.partner.fields.start-date')"
          :value="partner.start_date"
          container-class="col-12 col-md-6"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.partner.fields.end-date')"
          :value="partner.end_date"
          container-class="col-12 col-md-6"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.partner.fields.agreement-number')"
          :value="partner.agreement_number"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="alert alert-info"
      role="alert"
    >
      <i class="pi pi-info-circle" /> {{ $t('domains.program-groups.tabs.partner.no-partner') }}
    </div>
  </div>
  <Dialog
    v-model:visible="isShowDialog"
    modal
    :header="$t('domains.program-groups.tabs.partner.dialog-title')"
    class="w-75"
  >
    <ProgramGroupEditForm />
  </Dialog>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import Dialog from 'primevue/dialog'
import ProgramGroupEditForm from './ui/ProgramPartnerEditForm/ProgramPartnerEditForm.vue'
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { showEditDialog } from './utils'
import { isShowDialog } from './consts'
import FieldSet from '../FieldSet/FieldSet.vue'
import { useAlert } from '@/shared/composable'
import { useI18n } from 'vue-i18n'

const store = useProgramGroupStore()
const { programGroup, resources } = storeToRefs(store)
const { dialog } = useAlert()
const { t } = useI18n()

const partner = computed(() => {
  return programGroup.value ? programGroup.value.partner : null
})

const country = computed(() => {
  return resources.value?.countries.find(country => country.institution.find(i => i.id === partner.value?.institution_id))
})

const partnerUniversity = computed(() => {
  return country.value?.institution.find(i => i.id === partner.value?.institution_id)
})

const handleDelete = () => {
  dialog({
    text: t('phrases.are-you-sure'),
    onConfirm: async () => {
      if (!programGroup.value) return
      const res = await store.removeProgramGroupPartner(programGroup.value.code)

      if (res.status) {
        await store.fetchProgramGroup(programGroup.value.code)
      }
    }
  })
}
</script>
