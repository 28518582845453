<template>
  <div class="d-flex justify-content-end align-items-center mb-5">
    <i
      class="bi bi-pencil-square fs-2 ms-5"
      @click="showEditDialog()"
    />
  </div>
  <div v-if="description">
    <div class="container">
      <div class="row g-3">
        <FieldSet
          :title="$t('domains.program-groups.tabs.description.fields.description-kz')"
          :value="description.description_kz"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.description.fields.description-en')"
          :value="description.description_en"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.description.fields.description-ru')"
          :value="description.description_ru"
        />
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isShowDialog"
    modal
    :header="'domains.program-groups.tabs.description.dialog-title'"
    class="w-75"
  >
    <ProgramGroupDescriptionEditForm />
  </Dialog>
</template>
<script lang="ts" setup>
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { showEditDialog } from './utils'
import { isShowDialog } from './consts'
import ProgramGroupDescriptionEditForm from './ui/ProgramGroupDescriptionEditForm/ProgramGroupDescriptionEditForm.vue'
import Dialog from 'primevue/dialog'
import FieldSet from '../FieldSet/FieldSet.vue'

const store = useProgramGroupStore()
const { programGroup } = storeToRefs(store)

const description = computed(() => {
  return programGroup.value ? programGroup.value.description : null
})
</script>
