import type { StoreType } from '../types'
import { useAlert } from '@/shared/composable'
import { StatusMessageResponse } from '@/shared/types'
import deleteProgramGroupPartner from '../api/methods/deleteProgramGroupPartner'

export default async function removeProgramGroupPartner (this: StoreType, code: string): Promise<StatusMessageResponse> {
  const { error, success } = useAlert()
  const { ok, message } = await deleteProgramGroupPartner(code)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
