import { useAlert } from '@/shared/composable'
import { StoreType } from '../types'
import { PutProgramGroupDescriptionRequest } from '../api/methods/putProgramGroupDescription/types'
import putProgramGroupDescription from '../api/methods/putProgramGroupDescription'

export default async function editProgramGroupDescription (this: StoreType, code: string, data: PutProgramGroupDescriptionRequest) {
  const { error, success } = useAlert()
  const { ok, message } = await putProgramGroupDescription(code, data)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
