import { useAlert } from '@/shared/composable'
import { StoreType } from '../types'
import { PutProgramGroupOutcomeRequest } from '../api/methods/putProgramGroupOutcome/types'
import putProgramGroupOutcome from '../api/methods/putProgramGroupOutcome'

export default async function editProgramGroupOutcome (this: StoreType, code: string, id: number, data: PutProgramGroupOutcomeRequest) {
  const { error, success } = useAlert()
  const { ok, message } = await putProgramGroupOutcome(code, id, data)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
