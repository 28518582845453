import { ref } from 'vue'
import { GetMedicalCheckupsStudentsResponse } from '../store/api/methods/getStudents/types'

export const showDialog = ref<boolean>(false)
export const selected = ref<{full_name: string, department?: string, position?: string, student_id?: string, id: number, checkups: GetMedicalCheckupsStudentsResponse[0]['checkups']}>()
export const uploadExcelModalVisible = ref<boolean>(false)
export const showNotCofirmedCardsModal = ref<boolean>(false)
export const studentsValues = ref<any>({
  student_id: null,
  name: null,
  surname: null
})

export const employeesValues = ref<any>({
  name: null,
  surname: null
})

export const cardsValues = ref<any>({
  card_id: null
})
