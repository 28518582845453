import { useManageCourses } from '@/domains/AcademicCourses/store'
import { yearTermsToYearsOptions } from '@/domains/AcademicCourses/utils'
import { useAlert, usePagination } from '@/shared/composable'
import { useResource } from '@/shared/stores'
import { FormGroup } from '@/shared/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useCourseForm () {
  const { t } = useI18n()
  const { error } = useAlert()
  const { perPage } = usePagination()
  const resource = useResource()
  const store = useManageCourses()

  const tempValues = ref({
    year: undefined,
    department_code: undefined,
    code: undefined,
    name: undefined,
    equicode: undefined
  })

  const formFields = computed(():Array<FormGroup> => [
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: `${t('common.enter-course-code')}:`,
            class: 'mb-1'
          },
          name: 'code',
          type: 'text',
          value: tempValues.value.code || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.course-code') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: `${t('common.select-year')}:`,
            class: 'mb-1'
          },
          name: 'year',
          type: 'select',
          value: tempValues.value.year,
          options: yearTermsToYearsOptions(resource.getResourceYearTerms)
        }
      ]
    },
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.common.table.course-title')}:`,
            class: 'mb-1'
          },
          name: 'name',
          type: 'text',
          value: tempValues.value.name || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.course-title') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.select-department')}:`,
            class: 'mb-1'
          },
          name: 'department_code',
          type: 'slot',
          value: tempValues.value.department_code
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.table.course-equicode')}:`,
            class: 'mb-1'
          },
          name: 'equicode',
          type: 'text',
          value: tempValues.value.equicode || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.course-equicode') },
              'trim'
            ]
          }
        }
      ]
    }
  ])

  const onSubmit = async ({ values }) => {
    if (values.department_code === null) {
      error({ text: t('domains.academic.exam-dates.department-error') })
      return
    }
    values.code = values.code ? values.code.toUpperCase() : undefined

    const { status, message } = await store.search_course({ ...values, per_page: perPage.value, offset: 1 })
    if (!status) error({ text: message })

    store.setCourseSearchRequest({ ...tempValues.value, per_page: perPage.value, offset: 1 })
  }

  return {
    formFields,
    tempValues,
    resource,
    onSubmit
  }
}
