import { Nullable } from '@/shared/types'
import { ref } from 'vue'
import { EOutcomeDialogs } from '../enums'
import type { GetProgramGroupOutcomesResponse } from '@domains/AcademicProgramGroups/store/api/methods/getProgramGroupOutcomes/types'

export const isShowDialog = ref(false)

export const currentDialog = ref<Nullable<EOutcomeDialogs>>(null)

export const selectedOutcome = ref<Nullable<GetProgramGroupOutcomesResponse[number]>>()
