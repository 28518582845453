import getCards from '../api/methods/getCards'
import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { GetMedicalCheckupsCardsRequest } from '../api/methods/getCards/types'
import { useAlert } from '@/shared/composable'

export default async function fetchCards (this: StoreType, params: GetMedicalCheckupsCardsRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getCards(params)

  const { error } = useAlert()

  if (ok) {
    this.cards = data
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
