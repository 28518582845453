<template>
  <div>
    <div class="d-flex justify-content-end align-items-center mb-5">
      <i
        class="bi bi-pencil-square fs-2 ms-5"
        @click="showEditDialog()"
      />
    </div>
    <div class="container">
      <div class="row g-3">
        <FieldSet
          :title="$t('domains.program-groups.title-kz')"
          :value="programGroup?.title_kz"
        />

        <FieldSet
          :title="$t('domains.program-groups.title-en')"
          :value="programGroup?.title_en"
        />

        <FieldSet
          :title="$t('domains.program-groups.title-ru')"
          :value="programGroup?.title_ru"
        />

        <FieldSet
          :title="$t('domains.program-groups.program-group-type')"
          :value="programGroup?.type"
          container-class="col-12 col-md-6"
        />

        <FieldSet
          :title="$t('domains.program-groups.education-level')"
          :value="programGroup?.education_level.title"
          container-class="col-12 col-md-6"
        />

        <FieldSet
          :title="$t('domains.program-groups.code')"
          :value="programGroup?.code"
          container-class="col-12 col-md-6"
        />

        <FieldSet
          v-if="programGroup?.program_type"
          :title="$t('domains.program-groups.program-type')"
          :value="programGroup?.program_type?.title"
          container-class="col-12 col-md-6"
        />
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isShowDialog"
    modal
    :header="$t('domains.program-groups.tabs.general.dialog-title')"
    class="w-75"
  >
    <ProgramGroupEditForm />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog'
import ProgramGroupEditForm from './ui/ProgramGroupEditForm/ProgramGroupEditForm.vue'
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { isShowDialog } from './consts'
import { showEditDialog } from './utils'
import FieldSet from '../FieldSet/FieldSet.vue'

const store = useProgramGroupStore()
const { programGroup } = storeToRefs(store)
</script>
