<template>
  <div class="d-flex justify-content-end mb-5">
    <programs-view-buttons id="view-buttons" />
  </div>

  <ProgramTools
    :is-reference-tools-active="isReferenceToolsActive"
    :toogle-reference-tools-active="toogleReferenceToolsActive"
  />

  <template
    v-for="(items, index) in core"
    :key="index"
  >
    <pms-row id="curricula">
      <template
        v-for="(term, cIndex) in items"
        :key="cIndex"
      >
        <pms-grid
          :col="columnState ==='two-line' ? 6 : 12"
          style="position: relative;"
        >
          <TrackTerm
            :term="term"
            :track-id="curricula.information.track.id"
            :is-reference-tools-active="isReferenceToolsActive"
            :checked-core-courses="checkedCoreCourses"
          />
        </pms-grid>
      </template>
    </pms-row>
  </template>
  <pms-card
    id="total-credit"
    class="mt-3 mb-2"
    :header="false"
    :stretch="100"
  >
    <pms-smart-table
      :headers="totalHeaders"
      :items="allCoursesTotal"
    >
      <template #title="{value}">
        <span class="fw-bold">
          {{ $t(value) }}
        </span>
      </template>
    </pms-smart-table>
  </pms-card>

  <ViewProgramActionButtons
    v-if="checkedCoreCoursesArray.length > 0"
    :courses="checkedCoreCoursesArray"
  />
</template>
<script setup lang="ts">
import { PropType, computed, ref } from 'vue'
import { AcademicManageCurriculumViewResponse } from '@/shared/swagger'

import { columnState, totalHeaders } from '../../CurriculaViewPage/values'
import { getCoursesByTerm, getTotalCourse } from '../utils'
import permissionCodes from '@/shared/values/permissionCodes'
import ProgramsViewButtons from './ProgramsViewButtons.vue'
import { TotalCourse } from '../types'
import ViewProgramActionButtons from './ViewProgramActionButtons.vue'
import TrackTerm from './TrackTerm.vue'
import ProgramTools from './ProgramTools.vue'

// Reference tools
const isReferenceToolsActive = ref(false)
const checkedCoreCourses = ref<Record<number, boolean>>({})
const toogleReferenceToolsActive = () => {
  isReferenceToolsActive.value = !isReferenceToolsActive.value

  if (!isReferenceToolsActive.value) {
    checkedCoreCourses.value = {}
  }
}

const checkedCoreCoursesArray = computed(() => Object.keys(checkedCoreCourses.value).filter(key => checkedCoreCourses.value[key] === true))

const props = defineProps({
  curricula: {
    type: Object as PropType<AcademicManageCurriculumViewResponse>,
    required: true
  },
  tab: {
    type: String,
    required: true
  }
})

const allCoursesTotal = computed(() => getTotalCourse(getCoursesByTerm(-1, props.curricula.groups)))

const core = computed(() => {
  const result: Array<Array<{ term: number; courses: (AcademicManageCurriculumViewResponse['groups'][0])['items']; total: Array<TotalCourse>}>> = []
  const terms = props.curricula.information.terms
  let term = 1

  if (columnState.value === 'one-line') {
    for (let i = 1; i <= terms; i++) {
      result.push([{ term: term++, courses: getCoursesByTerm(i, props.curricula.groups), total: getTotalCourse(getCoursesByTerm(i, props.curricula.groups)) }])
    }
  } else {
    for (let i = 1; i <= terms; i += 2) {
      result.push([
        { term: term++, courses: getCoursesByTerm(i, props.curricula.groups), total: getTotalCourse(getCoursesByTerm(i, props.curricula.groups)) },
        { term: term++, courses: getCoursesByTerm(i + 1, props.curricula.groups), total: getTotalCourse(getCoursesByTerm(i + 1, props.curricula.groups)) }
      ])
    }
  }
  return result
})
</script>
<style scoped lang="scss">
.pinned {
  position: absolute;
  font-weight: 600;
  z-index: 0;
  opacity: 0.3;
}
</style>
