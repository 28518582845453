export enum CheckupStatus {
    'CONFIRMED' = 'confirmed',
    'CREATED' = 'created',
    'EDITING' = 'editing',
}

export enum ViewValues {
    'STUDENTS' = 'students',
    'EMPLOYEES' = 'employees',
    'CARDS' = 'cards'
}
