<template>
  <ProgramGroupOutcomeAddButton />
  <ProgramGroupOutcomeCards
    class="mt-5"
    :type="type"
  />

  <Dialog
    v-model:visible="isShowDialog"
    modal
    :header="dialogTitle"
    class="w-75"
    @hide="() => {
      currentDialog = null
    }"
  >
    <AddProgramGroupOutcomeForm v-if="currentDialog === EOutcomeDialogs.AddOutcome" />
    <ProgramGroupOutcomeEditForm v-if="currentDialog === EOutcomeDialogs.EditOutcome" />
  </Dialog>
  <div />
</template>
<script lang="ts" setup>
import ProgramGroupOutcomeAddButton from './ui/ProgramGroupOutcomeAddButton/ProgramGroupOutcomeAddButton.vue'
import { currentDialog, isShowDialog } from './consts'
import { EOutcomeDialogs } from './enums'

import Dialog from 'primevue/dialog'
import AddProgramGroupOutcomeForm from './ui/ProgramGroupOutcomeAddButton/ui/AddProgramGroupOutcomeForm/AddProgramGroupOutcomeForm.vue'
import ProgramGroupOutcomeCards from './ui/ProgramGroupOutcomeCards/ProgramGroupOutcomeCards.vue'
import ProgramGroupOutcomeEditForm from './ui/ProgramGroupOutcomeEditForm/ProgramGroupOutcomeEditForm.vue'
import { EOutcomeTypes } from '@/domains/AcademicProgramGroups/store/enums'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps<{ type: EOutcomeTypes }>()
const dialogTitle = computed(() => {
  switch (currentDialog.value) {
    case EOutcomeDialogs.AddOutcome:
      return t('common.add')
    case EOutcomeDialogs.EditOutcome:
      return t('common.edit')
    default:
      return ''
  }
})
</script>
