import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { PutCheckupRequest } from '../api/methods/putCheckup/types'
import { useAlert } from '@/shared/composable'
import putCheckup from '../api/methods/putCheckup'
import { ViewValues, showNotCofirmedCardsModal, studentsValues, employeesValues, cardsValues } from '../../values'

export default async function putCheckupAction (this: StoreType, body: PutCheckupRequest, update: boolean): Promise<StatusMessageResponse> {
  const { ok, data, message } = await putCheckup(body)

  const { error, success } = useAlert()

  if (ok) {
    update && await updateData.call(this)
    this.notConfirmedCards = data
    showNotCofirmedCardsModal.value = !!data.length
    !data.length && success({ text: 'domains.services.medical_checkup.success' })
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}

const updateData = async function (this: StoreType): Promise<void> {
  const assignNullIfEmpty = (value: string) => (value === '' ? undefined : value)

  switch (this.view) {
    case ViewValues.STUDENTS: {
      const value = {
        student_id: assignNullIfEmpty(studentsValues.value.student_id),
        name: assignNullIfEmpty(studentsValues.value.name),
        surname: assignNullIfEmpty(studentsValues.value.surname)
      }
      await this.fetchStudents({ ...value })
      break
    }
    case ViewValues.EMPLOYEES:
      await this.fetchEmployees({ ...employeesValues.value })
      break
    case ViewValues.CARDS:
      await this.fetchCards({ ...cardsValues.value })
      break
  }
}
