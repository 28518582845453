import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { PutProgramGroupOutcomeRequest } from './types'
import { UnknownResponse } from '@/shared/swagger'

export default async function putProgramGroupOutcome (code: string, id: number, body: PutProgramGroupOutcomeRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<PutProgramGroupOutcomeRequest, UnknownResponse>({
    url: `service/program-groups/${code}/outcomes/${id}`,
    body,
    loader: 'put-service/program-groups/outcomes'
  })
  return response
}
