<template>
  <pms-card
    v-if="hasPermission"
    :title="$t('common.tools')"
    class="mt-5 mb-5"
  >
    <div class="d-flex flex-row gap-5 flex-wrap">
      <!-- Reference tool buttons -->
      <PermissionCheck :permission-code="permissionCodes.edit_add_reference_educational_program_managements_curriculla.code">
        <pms-button
          v-if="isReferenceToolsActive"
          class="btn btn-warning"
          :variant="'outlined'"
          :color="'warning'"
          @Click="toogleReferenceToolsActive"
        >
          <pms-kt-icon
            :icon-name="'arrow-mix'"
            class="text-warning"
          />
          {{ t('domains.academic.curricula.view.cancel-edit-references') }}
        </pms-button>

        <pms-button
          v-else
          class="btn-primary-outlined"
          :variant="'outlined'"
          @Click="toogleReferenceToolsActive"
        >
          <pms-kt-icon
            :icon-name="'arrow-mix'"
            class="text-success"
          />
          {{ t('domains.academic.curricula.view.edit-references') }}
        </pms-button>
      </PermissionCheck>

      <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
        <!-- Add course button -->
        <AddCourseButton
          v-if="trackId"
          :track-id="+trackId"
        />
      </PermissionCheck>

      <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
        <!-- Add elective slot button -->
        <AddElectiveSlotButton
          v-if="trackId"
          :track-id="+trackId"
        />
      </PermissionCheck>
    </div>
  </pms-card>
</template>
<script lang="ts" setup>
import AddCourseButton from '../../../shared/ui/AddCourseButton.vue'
import AddElectiveSlotButton from '../../../shared/ui/AddElectiveSlotButton.vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'
import { useUser } from '@/shared/stores'
import { computed } from 'vue'

const userStore = useUser()

const permissions = computed(() => userStore.getModulePermissions)
const hasPermission = computed(() => {
  const permissionToReference = permissions.value.find(permission => permission.code === permissionCodes.edit_add_reference_educational_program_managements_curriculla.code)
  const permissionToEdit = permissions.value.find(permission => permission.code === permissionCodes.edit_curricula.code)
  return permissionToEdit || permissionToReference
})
const route = useRoute()
const trackId = route.query.track
const { t } = useI18n()

defineProps({
  isReferenceToolsActive: {
    required: true,
    type: Boolean
  },
  toogleReferenceToolsActive: {
    required: true,
    type: Function
  }
})
</script>
