import type { StoreType } from '../types'
import { useAlert } from '@/shared/composable'
import { StatusMessageResponse } from '@/shared/types'
import deleteProgramGroup from '../api/methods/deleteProgramGroup'

export default async function removeProgramGroup (this: StoreType, code: string): Promise<StatusMessageResponse> {
  const { error, success } = useAlert()
  const { ok, message } = await deleteProgramGroup(code)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
