import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

export default {
  layout: 'default',
  path: '/academic/program-groups',
  children: [
    {
      path: '',
      name: 'AcademicProgramGroupsPage',
      component: () => import('./AcademicProgramGroups.vue'),
      beforeEnter: []
    },
    {
      path: ':code',
      name: 'AcademicProgramGroupPage',
      component: () => import('./features/AcademicProgramGroup/AcademicProgramGroup.vue'),
      beforeEnter: []
    }
  ]
} as RouteRecordWithLayout
