import { PropType, Teleport, defineComponent, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { isTranslatable } from '@shared/utils'
import { Breadcrumb } from '@/shared/types'
import { RouteLocationNormalized, RouteLocationRaw, useRouter } from 'vue-router'

const toolbar = defineComponent({
  name: 'PageToolbar',
  props: {
    title: {
      type: String as PropType<string>,
      default: 'Please, set a title'
    },
    description: {
      type: String as PropType<string>,
      default: 'Please, set a description'
    },
    tour: {
      type: Boolean,
      required: false,
      default: true
    },
    disableSticky: {
      type: Boolean,
      default: false
    },
    breadcrumbs: {
      type: Array as PropType<Array<Breadcrumb>>,
      required: false,
      default: () => []
    },
    goBack: {
      type: Object as PropType<RouteLocationRaw>,
      required: false,
      default: () => null
    }
  },
  emits: ['launch', 'update:title'],
  setup (props, { emit, slots }) {
    const { t } = useI18n()
    const tour = () => emit('launch', true)
    const router = useRouter()

    const stickOnScroll = () => {
      if (props.disableSticky) return

      const navbar = document.getElementById('kt_app_toolbar')
      const sticky = navbar?.offsetTop || 0

      if (window.pageYOffset >= sticky) {
        navbar?.classList.add('toolbar-sticky')
      } else {
        navbar?.classList.remove('toolbar-sticky')
      }
    }

    const toRoute = (route: RouteLocationNormalized) => {
      router.push(route)
    }

    onMounted(() => { window.onscroll = () => stickOnScroll() })

    onUnmounted(() => { window.onscroll = null })
    return () => (
      <Teleport to="#pageHeaderToolbar">
        <div
          id="kt_app_toolbar"
          class="app-toolbar py-3 py-lg-6 mb-6"
          style="background-color: var(--bs-app-toolbar-base-bg-color) !important;"
        >
          <div
            id="kt_app_toolbar_container"
            class="app-container container-fluid d-flex flex-stack flex-wrap flex-md-nowrap"
          >
            <div class="d-flex align-items-start flex-shrink-0 me-5">
            <div class="d-flex gap-2 align-items-center">
              {props.goBack && <router-link
                to={props.goBack}
                type="button"
                class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
              >
                <pms-kt-icon
                  icon-name="arrow-left"
                  class="m-0 p-0"
                />
              </router-link>}
              <div class={'d-flex flex-column gap-1'} >

                <h1
                  class="page-heading text-dark fw-bold fs-2"
                  style={{
                    marginBottom: '1px'
                  }}
                >
                  { isTranslatable(props.title) ? t(props.title) : props.title }
                </h1>

                {
                  props.breadcrumbs.length
                    ? (
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        {
                          props.breadcrumbs.map((item, index) => (
                            <li class="breadcrumb-item" key={index} >
                              {
                                item.route || item.to
                                  ? item.route
                                    ? (<span class="text-primary cursor-pointer" onClick={() => toRoute(item.route as RouteLocationNormalized)}>{item.text}</span>)
                                    : (<router-link to={item.to} >{item.text}</router-link>)
                                  : <span>{item.text}</span>
                              }
                            </li>
                          ))
                        }

                      </ol>
                    </nav>
                      )
                    : (<span></span>)
                }
              </div>
              </div>
              {/* PMS-T-1788 Временно убрали {
                props.tour && (
                  <button
                    onClick={ () => tour() }
                    title={ t('phrases.need-help') }
                    type="button"
                    class="btn btn-sm btn-light-primary px-4 py-2"
                    style={{
                      marginLeft: '0.8rem',
                      padding: '0.3rem 0.9rem !important'
                    }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-trigger="hover"
                    data-bs-original-title={ t('phrases.need-help') }
                  >
                    { t('phrases.need-help') }
                  </button>
                )
              } */}
            </div>
            <div class="d-flex align-items-center overflow-auto">
              { slots.action ? slots.action() : null }
            </div>
          </div>
        </div>
      </Teleport>
    )
  },
  watch: {
    title: {
      immediate: true,
      handler (value) {
        this.$emit('update:title', value)
      }
    }
  }
})

export default toolbar
