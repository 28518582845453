import { computed, ref } from 'vue'
import { useProgramGroupStore } from '../store'
import { storeToRefs } from 'pinia'
import { useLocale } from '@/shared/composable'

const store = useProgramGroupStore()
const { resources } = storeToRefs(store)
const { locale, locales } = useLocale()

const currentLocale = computed(() => locales.value.find((item) => item.short === locale.value) || locales.value[0])

type ShortLang = 'kz' | 'ru' | 'en'

export const currentLangShort = computed<ShortLang>(() => {
  return currentLocale.value.short.split('-')[0] as ShortLang
})

export const isShowDialog = ref(false)

export const degreeTypeOptions = computed(() => {
  if (!resources.value) return []
  return resources.value.degree_types.map((degree_type) => {
    return {
      label: degree_type.title,
      value: degree_type.type
    }
  })
})

export const educationLevelOptions = computed(() => {
  if (!resources.value) return []
  return resources.value.education_levels.map((degree_type) => {
    return {
      label: degree_type.title,
      value: degree_type.code
    }
  })
})

export const programGroupTypeOptions = computed(() => {
  if (!resources.value) return []
  return resources.value.program_group_types.map((program_group_type) => {
    return {
      label: program_group_type,
      value: program_group_type
    }
  })
})

export const programGroupCodeMask = {
  mask: '********************', // "*" - 20 symbols max
  tokens: {
    A: { pattern: /[a-zA-Z0-9]/ } // Только английские буквы (a-z, A-Z) и цифры (0-9)
  }
}
