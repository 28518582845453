export enum ProgramGroupModules {
    education_levels = 'education_levels',
    degree_types = 'degree_types',
    countries = 'countries',
    program_group_types = 'program_group_types'
}

/**
 * Example
 * ```
 * {
 *  "modules": [
 *    "education_levels"
 *  ]
 * }
 * ```
 */
export type getProgramGroupsResourcesRequest = {
  modules: ProgramGroupModules[]
}

/**
 * Example
 * ```
 * {
 *  "education_levels": [
 *    {
 *      "code": "B",
 *      "title": "Bachelor"
 *    }
 *  ],
 *  "countries": [
 *    {
 *      "address_id": 1053,
 *      "country_code": "LT",
 *      "country": "Lithuania",
 *      "institution": [
 *        {
 *          "id": 3104,
 *          "title": "Siauliai University",
 *          "equ_code": 103
 *        }
 *      ]
 *    }
 *  ],
 *  "degree_types": [
 *    {
 *      "type": "INF 330",
 *      "title": "Information storage management and virtualization",
 *      "is_visible": 2023
 *    }
 *  ],
 *  "program_group_types": [
 *    "TRAINING_DIRECTION"
 *  ],
 *  "user_types": [
 *    "S"
 *  ],
 *  "years": [
 *    {
 *      "year": 2023
 *    }
 *  ]
 * }
 * ```
 */
export type getProgramGroupsResourcesResponse = {
    'education_levels': {
      'code': string,
      'title': string
    }[],
    'countries': {
      'address_id': number,
      'country_code': string,
      'country': string,
      'institution': [
        {
          'id': number,
          'title': string,
          'equ_code': number
        }
      ]
    }[],
    'degree_types': {
      'type': string,
      'title': string,
      'is_visible': number
    }[],
    'program_group_types': string[],
    'user_types': string[],
    'years': {
      'year': number
    }[]
  }
