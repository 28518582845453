import { GetProgramGroupsResponse } from '@/domains/AcademicProgramGroups/store/api/methods/getProgramGroups/types'
import { TFilters } from '../types'

export function filterTree (data: GetProgramGroupsResponse, filters: TFilters): GetProgramGroupsResponse {
  const matchesFilters = (node: GetProgramGroupsResponse[number]): boolean => {
    return (
      (filters.degree_type.trim() === '' || node.program_type?.type === filters.degree_type.trim()) &&
        (filters.education_level.trim() === '' || node.education_level.code === filters.education_level)
    )
  }

  const findParents = (node: GetProgramGroupsResponse[number], visited: Set<string>): GetProgramGroupsResponse => {
    const parents: GetProgramGroupsResponse = []
    let currentNode = node

    while (currentNode.parent_code !== null) {
      const parent = data.find((item) => item.code === currentNode.parent_code)

      if (!parent || visited.has(parent.code)) {
        break
      }

      visited.add(parent.code)
      parents.push(parent)
      currentNode = parent
    }

    return parents
  }

  const filteredNodes: GetProgramGroupsResponse = []
  const visited: Set<string> = new Set()

  data.forEach((node) => {
    if (matchesFilters(node)) {
      // Добавляем текущий узел
      filteredNodes.push(node)

      // Добавляем родителей текущего узла
      const parents = findParents(node, visited)
      filteredNodes.push(...parents)
    }
  })

  // Удаляем дубли
  const uniqueNodes = Array.from(new Set(filteredNodes.map((node) => node.code))).map((code) =>
    filteredNodes.find((node) => node.code === code)!
  )

  return uniqueNodes
}
