import { useAlert } from '@/shared/composable'
import { StoreType } from '../types'
import getProgramGroupOutcomes from '../api/methods/getProgramGroupOutcomes'
import { GetProgramGroupOutcomeRequest } from '../api/methods/getProgramGroupOutcomes/types'
import { EOutcomeTypes } from '../enums'

export default async function fetchProgramGroupOutcomes (this: StoreType, code: string, query: GetProgramGroupOutcomeRequest) {
  const { error, success } = useAlert()
  const { ok, message, data } = await getProgramGroupOutcomes(code, query)

  const splitted = {
    [EOutcomeTypes.Program]: data.filter(o => o.type === EOutcomeTypes.Program),
    [EOutcomeTypes.Student]: data.filter(o => o.type === EOutcomeTypes.Student)
  }
  this.outcomes = splitted

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
