<template>
  <Dialog
    v-model:visible="showNotCofirmedCardsModal"
    modal
    :draggable="false"
    :style="{ width: '50rem' }"
  >
    <div class="d-flex flex-column gap-3">
      <div class="d-flex flex-column gap-2 align-items-center">
        <span class="fs-2 fw-bold">{{ $t('domains.services.medical_checkup.attention') }}</span>
        <span class="fs-5">{{ $t('domains.services.medical_checkup.attention_description') }}</span>
      </div>
      <pms-button
        variant="outlined"
        color="success"
        size="sm"
        class="align-self-end"
        @click="downloadExel()"
      >
        <i class="bi bi-file-earmark-excel-fill color-success" />
        {{ $t( 'domains.services.medical_checkups.download_excel' ) }}
      </pms-button>
      <pms-smart-table
        :headers="notConfirmedCardsHeader"
        :items="notConfirmedCards"
      />
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { showNotCofirmedCardsModal, notConfirmedCardsHeader } from '../values'
import { useMedicalCheckupsStore } from '../store'
import { storeToRefs } from 'pinia'
import Dialog from 'primevue/dialog'
import { utils, writeFile } from 'xlsx'

const store = useMedicalCheckupsStore()
const { notConfirmedCards } = storeToRefs(store)

const downloadExel = () => {
  const wb = utils.book_new()
  const wsData = notConfirmedCards.value?.map((card) => [
    `${card.user_id}`,
    `${card.checkup_date}`
  ]) ?? []

  const ws = utils.aoa_to_sheet([['id', 'date'], ...wsData])

  ws['!cols'] = [{ wch: 20 }, { wch: 30 }]

  utils.book_append_sheet(wb, ws, 'Exam Dates')
  writeFile(wb, 'MedicalCheckup.xlsx')
}
</script>
