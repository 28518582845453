import getAcademicManageCurriculumNavigation from '../api/methods/getCurriculumNavigation'
import type { StoreType } from '../types'

export default async function fetchNavigation (this: StoreType, year: number): Promise<void> {
  const { data, ok } = await getAcademicManageCurriculumNavigation({ year })

  if (ok) {
    this.setNavigation(data)
  }
}
