<template>
  <pms-card
    :header="false"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <div class="container mt-5">
      <div class="d-flex justify-content-end align-items-center mb-5">
        <i
          class="bi bi-pencil-square fs-2 ms-5"
          @click="showEditDialog()"
        />

        <i
          class="bi bi-trash fs-2 ms-5 text-danger"
          @click="handleDelete"
        />
      </div>
      <div class="row g-3">
        <FieldSet
          :title="$t('domains.program-groups.tabs.outcome.fields.key')"
          :value="outcome.key"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.outcome.fields.type')"
          :value="getOutcomeTypeName(outcome.type as EOutcomeTypes)"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.outcome.fields.outcome-kz')"
          :value="outcome.outcome_kz"
          :expand-on-hover="true"
          :transition-duration="0.1"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.outcome.fields.outcome-en')"
          :value="outcome.outcome_en"
        />

        <FieldSet
          :title="$t('domains.program-groups.tabs.outcome.fields.outcome-ru')"
          :value="outcome.outcome_ru"
        />
      </div>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import type { GetProgramGroupOutcomesResponse } from '@/domains/AcademicProgramGroups/store/api/methods/getProgramGroupOutcomes/types'
import FieldSet from '../../../FieldSet/FieldSet.vue'
import { EOutcomeTypes } from '@/domains/AcademicProgramGroups/store/enums'
import { useI18n } from 'vue-i18n'

import { EOutcomeDialogs } from '../../enums'
import { showDialog } from '../../utils'
import { selectedOutcome } from '../../consts'
import { useAlert } from '@/shared/composable'
import { useProgramGroupStore } from '@domains/AcademicProgramGroups/store'

const props = defineProps<{ outcome: GetProgramGroupOutcomesResponse[number] }>()
const store = useProgramGroupStore()
const { dialog } = useAlert()
const { t } = useI18n()

const showEditDialog = () => {
  selectedOutcome.value = props.outcome
  showDialog(EOutcomeDialogs.EditOutcome)
}
const handleDelete = () => {
  dialog({
    text: t('phrases.are-you-sure'),
    onConfirm: async () => {
      const res = await store.deleteProgramGroupOutcome(props.outcome.cipher, props.outcome.id)

      if (res.status) {
        store.fetchProgramGroupOutcomes(props.outcome.cipher, { })
      }
    }
  })
}

const getOutcomeTypeName = (type: EOutcomeTypes) => {
  switch (type) {
    case EOutcomeTypes.Program:
      return t('domains.program-groups.tabs.outcome.types.program')
    case EOutcomeTypes.Student:
      return t('domains.program-groups.tabs.outcome.types.student')
  }
}
</script>
