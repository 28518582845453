import getEmployees from '../api/methods/getEmployees'
import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { GetMedicalCheckupsEmployeesRequest } from '../api/methods/getEmployees/types'
import { useAlert } from '@/shared/composable'

export default async function fetchEmployees (this: StoreType, params: GetMedicalCheckupsEmployeesRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getEmployees(params)

  const { error } = useAlert()

  if (ok) {
    this.employees = data
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
