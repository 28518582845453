import { FormGroup } from '@/shared/types'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { studentsValues, employeesValues, cardsValues } from './refs'

export function useForms () {
  const { t } = useI18n()

  const studentsFormFields = computed<Array<FormGroup>>(() => [
    {
      class: 'col-12 d-flex flex-row gap-3',
      fields: [
        {
          name: 'student_id',
          type: 'text',
          value: studentsValues.value.student_id,
          label: {
            text: `${t('common.search-field.student-id')}`,
            class: 'mb-2'
          },
          group: {
            class: 'col-4'
          }
        },
        {
          name: 'name',
          type: 'text',
          value: studentsValues.value.name,
          label: {
            text: `${t('common.search-field.name')}`,
            class: 'mb-2'
          },
          group: {
            class: 'col-4'
          }
        },
        {
          name: 'surname',
          type: 'text',
          value: studentsValues.value.surname,
          label: {
            text: `${t('common.search-field.surname')}`,
            class: 'mb-2'
          },
          group: {
            class: 'col-4'
          }
        }
      ]
    }
  ])

  const employeesFormFields = computed<Array<FormGroup>>(() => [
    {
      class: 'col-12 d-flex flex-row gap-3',
      fields: [
        {
          name: 'name',
          type: 'text',
          value: employeesValues.value.name,
          label: {
            text: `${t('common.search-field.name')}`,
            class: 'mb-2'
          },
          group: {
            class: 'col-6'
          }
        },
        {
          name: 'surname',
          type: 'text',
          value: employeesValues.value.surname,
          label: {
            text: `${t('common.search-field.surname')}`,
            class: 'mb-2'
          },
          group: {
            class: 'col-6'
          }
        }
      ]
    }
  ])

  const cardsFormFields = computed<Array<FormGroup>>(() => [
    {
      class: 'col-12 d-flex flex-row gap-3',
      fields: [
        {
          name: 'card_id',
          type: 'text',
          value: cardsValues.value.card_id,
          label: {
            text: `${t('common.search-field.card_id')}`,
            class: 'mb-2'
          },
          group: {
            class: 'col-6'
          }
        }
      ]
    }
  ])

  return {
    studentsFormFields,
    cardsFormFields,
    employeesFormFields
  }
}
