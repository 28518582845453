import { TreeSelectionKeys } from 'primevue/tree'
import { computed, onMounted, ref } from 'vue'
import { useProgramGroupStore } from '@domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { GetProgramGroupsResponse } from '@domains/AcademicProgramGroups/store/api/methods/getProgramGroups/types'
import { showDialog } from '@/domains/AcademicProgramGroups/utils'
import { TreeNode } from '../types'
import { focusedProgramGroupInList } from '../consts'
import { currentLangShort } from '@/domains/AcademicProgramGroups/consts'

export default function useProgramTreeModel () {
  const store = useProgramGroupStore()
  const { programGroupsFiltered } = storeToRefs(store)

  enum EProgramGroupActions {
    Add = 'Add',
  }

  // Преобразование mockData в формат дерева
  function transformToTree (
    data: GetProgramGroupsResponse | undefined, // Входной массив
    parentCode: string | null = null
  ): TreeNode<GetProgramGroupsResponse[number]>[] | undefined { // Возвращаемый тип
    return data
      ? data
        .filter(item => item.parent_code === parentCode)
        .map(item => {
          const label = item['title_' + currentLangShort.value]
          return {
            key: item.code,
            label: `${item.code} - ` + label,
            children: transformToTree(data, item.code), // Рекурсия
            item // Исходный элемент
          }
        })
      : undefined
  }

  onMounted(() => {
    store.fetchProgramGroups({ })
  })

  const treeData = computed(() => {
    return programGroupsFiltered.value ? transformToTree(programGroupsFiltered.value) : []
  })

  // Состояние раскрытых узлов
  const expandedKeys = ref<{ [key: string]: boolean }>({})

  // Обработчик для переключения состояния expand
  function toggleExpand (node: any) {
    if (node.key in expandedKeys.value) {
      delete expandedKeys.value[node.key]
    } else {
      expandedKeys.value[node.key] = true
    }
  }

  const selectedKeys = ref<TreeSelectionKeys | undefined>(undefined)

  function handleAdd (node) {
    showDialog()
    node ? focusedProgramGroupInList.value = node : focusedProgramGroupInList.value = null
  }
  function handleDelete (node) {
    store.removeProgramGroup(node.key)
    store.fetchProgramGroups({ })
  }

  return {
    expandedKeys,
    treeData,
    selectedKeys,
    toggleExpand,
    handleAdd,
    handleDelete,
    EProgramGroupActions
  }
}
