import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { PutProgramGroupRequest } from './types'
import { UnknownResponse } from '@/shared/swagger'

export default async function putProgramGroup (code: string, body: PutProgramGroupRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<PutProgramGroupRequest, UnknownResponse>({
    url: `service/program-groups/${code}`,
    body,
    loader: 'put-service/program-groups'
  })
  return response
}
