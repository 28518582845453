import type { StoreType } from '../types'
import type { QueryParams } from '../../feature/CurriculaViewPage/types'
import getAcademicManageCurriculumView from '../api/methods/getCurriculumView'
import { AcademicManageCurriculumViewRequest } from '../api/methods/getCurriculumView/types'

export default async function fetchCurricula (this: StoreType, params: Pick<QueryParams, 'track'>): Promise<void> {
  const { data, ok } = await getAcademicManageCurriculumView({ track_id: params.track } as unknown as AcademicManageCurriculumViewRequest)

  if (ok) {
    this.setAcademicCurriculumView(data)
  }
}
