import { FormGroup } from '@/shared/types'
import { computed, onMounted, ref, watch } from 'vue'
import { useProgramGroupStore } from '@domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import { ProgramGroupModules } from '@domains/AcademicProgramGroups/store/api/methods/getProgramGroupsResources/types'
import { useI18n } from 'vue-i18n'
import { degreeTypeOptions, educationLevelOptions } from '@/domains/AcademicProgramGroups/consts'
import { programGroupsFilterValues } from '../consts'
import { filterTree } from '../utils'

export default function useProgramFilterModel () {
  const store = useProgramGroupStore()
  const { programGroups, programGroupsFiltered } = storeToRefs(store)

  const { t } = useI18n()

  watch(programGroupsFilterValues.value, () => {
    if (!programGroups.value) return
    programGroupsFiltered.value = filterTree(programGroups.value, programGroupsFilterValues.value)
  })

  onMounted(() => {
    store.fetchProgramGroupsResources({ modules: [ProgramGroupModules.education_levels, ProgramGroupModules.degree_types, ProgramGroupModules.countries, ProgramGroupModules.program_group_types] })
  })

  const onHandleChange = ({ name, newValue }) => {
    programGroupsFilterValues.value[name] = newValue
  }

  const formFields = computed<Array<FormGroup>>(() => [
    {
      class: 'row',
      fields: [
        {
          label: {
            text: t('domains.program-groups.education-level'),
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4'
          },
          name: 'education_level',
          options: educationLevelOptions.value,
          type: 'select',
          value: programGroupsFilterValues.value.education_level
        },
        {
          label: {
            text: t('domains.program-groups.degree-type'),
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4'
          },
          name: 'degree_type',
          options: degreeTypeOptions.value,
          type: 'select',
          value: programGroupsFilterValues.value.degree_type
        }
      ]
    }]
  )

  return {
    formFields, onHandleChange
  }
}
