<template>
  <Dialog
    v-model:visible="visible"
    modal
    :header="t('domains.academic.single-student.information.advisor-opinion.add-opinion')"
    class="d-flex flex-column"
  >
    <div class="gap-3 mb-3">
      <div class="table-responsive">
        <table class="table table-row-dashed table-striped align-middle">
          <tbody>
            <tr>
              <td class="text-start ps-5">
                {{
                  $t("common.table.advisor")
                }}
              </td>
              <td class="text-start pe-5 fs-4">
                <span class="fw-bold fs-4">
                  {{ studentInformation?.advisor }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-start ps-5">
                {{
                  $t("common.table.date")
                }}
              </td>
              <td class="text-start pe-5 fs-4">
                <span class="fw-bold fs-4">
                  {{ new Date().toLocaleDateString() }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Textarea
        v-model="opinion.values.content"
        class="w-100"
      />
    </div>
    <div class="d-flex justify-content-end w-100">
      <pms-button
        class="btn btn-sm"
        @click="addAdvisorOpinion"
      >
        {{ $t('common.save') }}
      </pms-button>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import { useResource } from '@shared/stores'
import { opinion, visible } from '../values'
import { useAlert } from '@/shared/composable'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSingleStudent } from '@/domains/AcademicSingleStudent/store'

const { t } = useI18n()
const resource = useResource()
const store = useSingleStudent()
const studentInformation = computed(() => resource.getAcademicSingleStudentResourceInformation)
const { error, success } = useAlert()

const addAdvisorOpinion = async () => {
  visible.value = false
  const { status, message } = await store.postAdvisorOpinion(opinion.value.student, opinion.value.values)
  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
  opinion.value.values.content = ''
}
</script>
