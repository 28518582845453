<template>
  <div class="d-flex justify-content-between mb-3">
    <div class="d-flex align-items-center">
      <span class="fs-1 fw-bold">
        {{ $t('domains.editor.curricula.tabs.reference.title') }}
      </span>
    </div>
  </div>
  <pms-card
    v-if="emptyReferences"
    :header="false"
    class="mb-3"
  >
    <pms-no-data />
  </pms-card>
  <template
    v-for="(ref) in referenceTypes"
    :key="ref"
  >
    <pms-card
      v-if="curricula.references[ref].length > 0"
      id="references"
      class="mb-4"
      :title="`domains.editor.curricula.tabs.references-titles.${ref}-title`"
      :description="`domains.editor.curricula.tabs.references-titles.${ref}-description`"
      :options="{ body: {class: 'pt-2' }}"
      :expandable="true"
    >
      <pms-smart-table
        :headers="headers"
        :items="curricula.references[ref]"
      >
        <template #no="{idx}">
          {{ idx + 1 }}
        </template>
        <template #icon>
          <div class="text-center">
            <pms-kt-icon
              :icon-name="'arrow-mix'"
              class="text-success fs-1"
            />
          </div>
        </template>
        <template #reference="{ item }">
          {{ item.reference_code }} - {{ item.reference_title }}
        </template>
        <template #actions="{item}">
          <div
            :data-bs-original-title="$t('domains.editor.curricula.readonly.disabled-click-edit-to-enable')"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
          >
            <PermissionCheck :permission-code="permissionCodes.edit_add_reference_educational_program_managements_curriculla.code">
              <pms-button
                v-if="item.is_active"
                :size="'sm'"
                :text="'common.action'"
                class="btn btn-danger"
                :disabled="item._isLoading === true"
                @Click="handleToogleReferenceStatus(item, false)"
              >
                {{ t('domains.academic.curricula.reference.deactivate-button') }}
              </pms-button>
              <pms-button
                v-else
                :size="'sm'"
                :text="'common.action'"
                class="btn btn-success"
                :disabled="item._isLoading === true"
                @Click="handleToogleReferenceStatus(item, true)"
              >
                {{ t('domains.academic.curricula.reference.activate-button') }}
              </pms-button>
            </PermissionCheck>
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </template>
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'
import { referenceHeader as headers } from '../values'
import editReference from '@/shared/api/AcademicCurricula/editReference'
import { useAlert } from '@/shared/composable'
import { useI18n } from 'vue-i18n'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'
import { AcademicManageCurriculumViewResponse } from '@/domains/AcademicCurricula/store/api/methods/getCurriculumView/types'

const { error, dialog } = useAlert()
const { t } = useI18n()

const props = defineProps({
  curricula: {
    type: Object as PropType<AcademicManageCurriculumViewResponse>,
    required: true
  },
  tab: {
    type: String,
    required: true
  }
})

const handleToogleReferenceStatus = (item: typeof props.curricula.references['many_to_one'][0] & {_isLoading: boolean}, newStatus: boolean) => {
  dialog({
    text: t('domains.academic.curricula.view.toogle-reference-status'),
    onConfirm: async () => {
      item._isLoading = true

      const {
        status,
        message
      } = await editReference(item.id, {
        is_active: newStatus
      })

      if (status) {
        item.is_active = newStatus
      } else {
        error({
          text: message
        })
      }
      item._isLoading = false
    }
  })
}

const referenceTypes = [
  'one_to_one',
  'one_to_many',
  'many_to_one'
]

const emptyReferences = computed(() => {
  const empties: Array<boolean> = []

  for (const ref of referenceTypes) {
    props.curricula.references[ref].length === 0 && empties.push(true)
  }

  return empties.length === referenceTypes.length
})
</script>
