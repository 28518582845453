<template>
  <div
    class="d-flex flex-column gap-5"
  >
    <div
      v-for="outcome in outcomes[type]"
      :key="outcome.id"
    >
      <ProgramGroupOutcomeCard :outcome="outcome" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'
import ProgramGroupOutcomeCard from '../ProgramGroupOutcomeCard/ProgramGroupOutcomeCard.vue'
import { EOutcomeTypes } from '@/domains/AcademicProgramGroups/store/enums'

defineProps<{type: EOutcomeTypes}>()
const store = useProgramGroupStore()
const { outcomes } = storeToRefs(store)
</script>
