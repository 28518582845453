const printStyle = `
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 10px !important;
}

.separator {
  border-bottom-style: dashed;
  display: block;
  width: 100% !important;
  height: 0;
  border-bottom: 1px solid #dddddd;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
<style type="text/css" media="print">
  @page { size: landscape; }
</style>
`

export default (): void => {
  const table = document.getElementById('singleCourseSchedulePrint')
  const w = window.open()

  if (table && w) {
    let tableHTML = table.outerHTML

    tableHTML = tableHTML.replace(
      /(<td id="hours"[^>]*?>)([\s\S]*?<span[^>]*?>.*?<\/span>)([\s\S]*?<span[^>]*?>.*?<\/span>)([\s\S]*?<\/td>)/g,
      (match, startTag, firstSpan, secondSpan, remainingContent) => {
        const newSpanHTML = '<span class="text-info"> - </span>'
        return `${startTag}${firstSpan}${newSpanHTML}${secondSpan}${remainingContent}`
      }
    )

    w.document.body.innerHTML = `${tableHTML}${printStyle}`
    w.print()
    w.close()
  }
}
