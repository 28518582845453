import type { StoreType } from '../types'
import { useAlert } from '@/shared/composable'
import { StatusMessageResponse } from '@/shared/types'
import putPartner from '../api/methods/putPartner'
import { PutPartnerRequest } from '../api/methods/putPartner/types'

export default async function editProgramPartner (this: StoreType, code: string, params: PutPartnerRequest): Promise<StatusMessageResponse> {
  const { error, success } = useAlert()
  const { ok, message } = await putPartner(code, params)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
