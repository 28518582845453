<template>
  <div class="form-group">
    <label
      for="institution-select"
      class="form-label"
    >{{ $t('domains.program-groups.tabs.partner.title') }}:</label>
    <TreeSelect
      v-model="selectedInstitution"
      v-model:expanded-keys="expandedKeys"
      :options="treeOptions"
      :placeholder="$t('domains.program-groups.tabs.partner.title')"
      :panel-class="'custom-tree-panel'"
      class="form-control"
      input-id="institution-select"
      filter
      @node-expand="onNodeExpand"
      @node-collapse="onNodeCollapse"
      @update:model-value="onInstitutionChange"
    >
      <!-- Кастомный слот для рендера узлов -->
      <template #option="{ node }">
        <span
          class="tree-node-label"
          :class="{ 'not-selectable': !node.selectable }"
          @click="toggleNodeExpansion(node.key)"
        >
          {{ node.label }}
        </span>
      </template>
    </TreeSelect>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineModel } from 'vue'
import TreeSelect from 'primevue/treeselect'
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { storeToRefs } from 'pinia'

const store = useProgramGroupStore()
const { resources } = storeToRefs(store)

// Тип данных для элемента TreeSelect
interface TreeNode {
  key: string;
  label: string;
  selectable?: boolean;
  children?: TreeNode[];
}

const generateDefaultValue = (value: number) => {
  const a = {}
  a[`institution-${value}`] = true
  return a
}

const model = defineModel()

// Выбранный университет
const selectedInstitution = computed(() => {
  return model ? generateDefaultValue(model.value) : null
})

// Обработчик изменения выбранного значения
const onInstitutionChange = (newValue: any | null) => {
  if (!newValue) {
    model.value = null
  }
  const institutionKey = Object.keys(newValue)[0]

  selectedInstitution.value = newValue
  if (model && newValue) {
    // Извлекаем ID из ключа и обновляем модель
    const institutionId = parseInt(institutionKey.split('-')[1], 10)
    model.value = institutionId
  } else {
    model.value = null
  }
}

// Преобразование данных в формат TreeSelect
const treeOptions: TreeNode[] = resources.value
  ? resources.value.countries.map((country) => ({
    key: `country-${country.address_id}`,
    label: country.country,
    selectable: false, // Страны не выбираются
    children: country.institution.map((institution) => ({
      key: `institution-${institution.id}`,
      label: institution.title,
      selectable: true // Университеты доступны для выбора
    }))
  }))
  : []

// Раскрытые узлы
const expandedKeys = ref<Record<string, boolean>>({})

// Переключение состояния раскрытия узла
const toggleNodeExpansion = (key: string) => {
  if (expandedKeys.value[key]) {
    delete expandedKeys.value[key]
  } else {
    expandedKeys.value[key] = true
  }
}

// Обработчик события раскрытия узла
const onNodeExpand = (node: TreeNode) => {
  expandedKeys.value[node.key] = true
}

// Обработчик события сворачивания узла
const onNodeCollapse = (node: TreeNode) => {
  delete expandedKeys.value[node.key]
}
</script>

<style scoped>
/* Стили для панели TreeSelect */
.custom-tree-panel {
  max-height: 300px;
  overflow-y: auto;
}

/* Стили для интеграции с Bootstrap */
.form-control {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #212529;
}

.tree-node-label {
  cursor: pointer;
}

.not-selectable {
  color: gray;
  cursor: default;
}
</style>
