<template>
  <pms-smart-form
    :fields="formFields"
    @handleSubmit="onSubmit"
  >
    <template #parent_code>
      <ParentProgramGroupTreeSelect v-model="tempValues.parent_code" />
    </template>
    <template #formControls>
      <div class="d-flex justify-content-end mt-2">
        <pms-button
          type="submit"
          class="mt-2"
          :text="$t('common.save')"
        />
      </div>
    </template>
  </pms-smart-form>
</template>
<script setup lang="ts">
import { degreeTypeOptions, educationLevelOptions, programGroupTypeOptions } from '@/domains/AcademicProgramGroups/consts'
import { useProgramGroupStore } from '@/domains/AcademicProgramGroups/store'
import { FormGroup } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { hideEditDialog } from '../../utils'
import ParentProgramGroupTreeSelect from './ui/ParentProgramGroupTreeSelect/ParentProgramGroupTreeSelect.vue'

const store = useProgramGroupStore()
const { programGroup } = storeToRefs(store)

const tempValues = computed(() => {
  const data = {
    education_level: programGroup.value?.education_level.code,
    program_group_type: programGroup.value?.type,
    title_en: programGroup.value?.title_en,
    title_ru: programGroup.value?.title_ru,
    title_kz: programGroup.value?.title_kz,
    parent_code: programGroup.value?.parent_code,
    program_type: programGroup.value?.program_type?.type
  }
  return data
})

const onSubmit = async ({ values }) => {
  if (!programGroup.value) return
  const res = await store.editProgramGroup(programGroup.value.code, {
    education_level: values.education_level,
    program_group_type: values.program_group_type,
    title_en: values.title_en,
    title_ru: values.title_ru,
    title_kz: values.title_kz,
    parent_code: tempValues.value.parent_code ? tempValues.value.parent_code : null,
    program_type: values.program_type
  })

  if (res.status) {
    hideEditDialog()
    await store.fetchProgramGroup(programGroup.value.code)
  }
}

const { t } = useI18n()
const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        label: {
          text: t('domains.program-groups.program-group-type'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'program_group_type',
        options: programGroupTypeOptions.value,
        type: 'select',
        value: tempValues.value.program_group_type
      },
      // {
      //   label: {
      //     text: t('domains.program-groups.code'),
      //     class: 'mb-1 mt-2'
      //   },
      //   group: {
      //     class: 'col-6'
      //   },
      //   name: 'code',
      //   type: 'text',
      //   value: tempValues.value.code,
      //   vMask: programGroupCodeMask
      // },
      {
        label: {
          text: t('domains.program-groups.title-en'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'title_en',
        type: 'textarea',
        value: tempValues.value.title_en
      },
      {
        label: {
          text: t('domains.program-groups.title-ru'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'title_ru',
        type: 'textarea',
        value: tempValues.value.title_ru
      },
      {
        label: {
          text: t('domains.program-groups.title-kz'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'title_kz',
        type: 'textarea',
        value: tempValues.value.title_kz
      },
      {
        label: {
          text: t('domains.program-groups.education-level'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'education_level',
        options: educationLevelOptions.value,
        type: 'select',
        value: tempValues.value.education_level
      },
      {
        label: {
          text: t('common.parent_code'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'parent_code',
        type: 'slot',
        value: tempValues.value.parent_code
      },
      {
        label: {
          text: t('domains.program-groups.program-type'),
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'program_type',
        options: degreeTypeOptions.value, // program_type is degreeType from backend
        type: 'select',
        value: tempValues.value.program_type
      }
    ]
  }]
)
</script>
