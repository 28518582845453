import { computed } from 'vue'
import { IcsEvent } from '../types'
import { toIcs } from './toIcs'
import * as XLSX from 'xlsx'
import { useStore } from '../store'

const store = useStore()
const printStyle = `
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 10px !important;
}

.separator {
  border-bottom-style: dashed;
  display: block;
  width: 100% !important;
  height: 0;
  border-bottom: 1px solid #dddddd;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
<style type="text/css" media="print">
  @page { size: landscape; }
</style>
`

const exportScheduleToIcs = () => {
  const scheduleRecords = computed(() => store.getScheduleRecords)
  const events: Array<IcsEvent> = scheduleRecords.value.map((schedule) => {
    return {
      start: schedule.start_time,
      end: schedule.end_time,
      title: `${schedule.course_code}(${schedule.course_section}) ${schedule.course_title}`,
      description: `Instructor: ${schedule.course_instructor}, Speciality: ${schedule.speciality}`,
      location: schedule.room,
      recurrence: schedule.day
    }
  })
  const date = new Date().toISOString().slice(0, 10)
  toIcs(events, { startDate: date, directURL: false })
}

const onExcelDownloadAction = (): void => {
  const table = document.getElementById('singleCourseSchedulePrint')

  if (table) {
    let tableHTML = table.outerHTML

    tableHTML = tableHTML.replace(
      /(<td id="hours"[^>]*?>)([\s\S]*?<span[^>]*?>.*?<\/span>)([\s\S]*?<span[^>]*?>.*?<\/span>)([\s\S]*?<\/td>)/g,
      (match, startTag, firstSpan, secondSpan, remainingContent) => {
        const newSpanHTML = '<span class="text-info"> - </span>'
        return `${startTag}${firstSpan}${newSpanHTML}${secondSpan}${remainingContent}`
      }
    )

    const styledHtml = `
      <html>
        <head>
          <style>
            table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }

            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
              font-size: 10px !important;
            }

            .separator {
              border-bottom-style: dashed;
              display: block;
              width: 100% !important;
              height: 0;
              border-bottom: 1px solid #dddddd;
            }

            tr:nth-child(even) {
              background-color: #dddddd;
            }
          </style>
        </head>
        <body>
          ${tableHTML}
        </body>
      </html>`
    const url = 'data:application/vnd.ms-excel,' + escape(styledHtml)
    window.open(url, '_blank')
  }
}

const onPrintDownloadAction = (): void => {
  const table = document.getElementById('singleCourseSchedulePrint')
  const w = window.open()

  if (table && w) {
    let tableHTML = table.outerHTML

    tableHTML = tableHTML.replace(
      /(<td id="hours"[^>]*?>)([\s\S]*?<span[^>]*?>.*?<\/span>)([\s\S]*?<span[^>]*?>.*?<\/span>)([\s\S]*?<\/td>)/g,
      (match, startTag, firstSpan, secondSpan, remainingContent) => {
        const newSpanHTML = '<span class="text-info"> - </span>'
        return `${startTag}${firstSpan}${newSpanHTML}${secondSpan}${remainingContent}`
      }
    )

    w.document.body.innerHTML = `${tableHTML}${printStyle}`
    w.print()
    w.close()
  }
}

export const exportSchedule = (module) => {
  if (module === 'google' || module === 'microsoft') {
    exportScheduleToIcs()
  }
  if (module === 'file-earmark-excel-fill') {
    onExcelDownloadAction()
  }
  if (module === 'printer-fill') {
    onPrintDownloadAction()
  }
}
