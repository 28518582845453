import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { GetProgramGroupResponse } from './types'
import { UnknownRequest } from '@/shared/swagger'

export default async function getProgramGroup (code: string): Promise<AxiosResponse<GetProgramGroupResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, GetProgramGroupResponse>({
    url: `service/program-groups/${code}`,
    body: { },
    loader: 'get-service/program-group'
  })

  return response
}
