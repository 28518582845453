import { useAlert } from '@/shared/composable'
import { StoreType } from '../types'
import { PostProgramGroupOutcomeRequest } from '../api/methods/postProgramGroupOutcome/types'
import postProgramGroupOutcome from '../api/methods/postProgramGroupOutcome'

export default async function createProgramGroupOutcome (this: StoreType, code: string, data: PostProgramGroupOutcomeRequest) {
  const { error, success } = useAlert()
  const { ok, message } = await postProgramGroupOutcome(code, data)

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true })
  }

  return { status: ok, message }
}
