import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import { EOutcomeTypes } from './enums'

export const outcomeDefaultValues = { [EOutcomeTypes.Program]: null, [EOutcomeTypes.Student]: null }

export const useProgramGroupStore = defineStore<Name, State, Getters, Actions>('Academic.useProgramGroups', {
  actions: {
    ...actions
  },

  getters: {
    //
  },

  state () {
    return {
      programGroup: null,
      programGroups: null,
      outcomes: outcomeDefaultValues,
      programGroupsFiltered: null,
      resources: null
    }
  }
})
