<template>
  <pms-card
    v-if="store.view === 'students' && students"
    class="mt-4"
    :title="$t( 'domains.services.medical_checkups.result' )"
  >
    <pms-grid
      :col="12"
      :order-mb="2"
    >
      <pms-loader v-if="loader" />
      <div
        v-else
        class="d-flex flex-column gap-3"
      >
        <pms-smart-table
          :items="students"
          :headers="studentsHeaders"
        >
          <template #no="{ idx }">
            {{ idx + 1 }}
          </template>
          <template #checkup_date="{ item }">
            <span>{{ item.checkups.filter(checkup => checkup.is_last)[0]?.checkup_date }}</span>
          </template>
          <template #action="{ item }">
            <pms-button
              :size="'sm'"
              @click="setUser(item)"
            >
              <pms-kt-icon
                :icon-name="'pencil'"
                class="fw-bold fs-3 p-0"
              />
              {{ $t( 'domains.services.medical_checkups.edit' ) }}
            </pms-button>
          </template>
        </pms-smart-table>
      </div>
    </pms-grid>
  </pms-card>
  <pms-card
    v-if="store.view === 'employees' && employees"
    class="mt-4"
    :title="$t( 'domains.services.medical_checkups.result' )"
  >
    <pms-grid
      :col="12"
      :order-mb="2"
    >
      <pms-loader v-if="loader" />
      <div
        v-else
      >
        <pms-smart-table
          :items="employees"
          :headers="employeesHeaders"
        >
          <template #no="{ idx }">
            {{ idx + 1 }}
          </template>
          <template #position="{ item }">
            <span class="fw-bold">{{ item.position }} - </span>
            <span>({{ item.department }})</span>
          </template>
          <template #checkup_date="{ item }">
            <span>{{ item.checkups?.filter(checkup => checkup.is_last)[0]?.checkup_date }}</span>
          </template>
          <template #action="{ item }">
            <pms-button
              :size="'sm'"
              @click="setUser(item)"
            >
              <pms-kt-icon
                :icon-name="'pencil'"
                class="fw-bold fs-3 p-0"
              />
              {{ $t( 'domains.services.medical_checkups.edit' ) }}
            </pms-button>
          </template>
        </pms-smart-table>
      </div>
    </pms-grid>
  </pms-card>
  <pms-card
    v-if="store.view === 'cards' && cards"
    class="mt-4"
    :title="$t( 'domains.services.medical_checkups.result' )"
  >
    <pms-grid
      :col="12"
      :order-mb="2"
    >
      <pms-loader v-if="loader" />
      <div
        v-else
      >
        <pms-smart-table
          :items="cards"
          :headers="cardsHeaders"
        >
          <template #no="{ idx }">
            {{ idx + 1 }}
          </template>
          <template #full_name="{ item }">
            <span class="fw-bold"> {{ item.employee ? item.employee?.full_name : item.student?.full_name }}</span>
          </template>
          <template #info="{ item }">
            <span class="fw-bold">{{ item.employee ? item.employee?.position : item.student.student_id }} </span>
            <span v-if="item.employee"> - ({{ item.employee ? item.employee?.department : '' }})</span>
          </template>
          <template #checkup_date="{ item }">
            <span>{{ item.employee ? item.employee.checkups?.filter(checkup => checkup.is_last)[0]?.checkup_date : item.student?.checkups?.filter(checkup => checkup.is_last)[0]?.checkup_date }}</span>
          </template>
          <template #action="{ item }">
            <pms-button
              :size="'sm'"
              @click="setUser(item.employee ? item.employee : item.student)"
            >
              <pms-kt-icon
                :icon-name="'pencil'"
                class="fw-bold fs-3 p-0"
              />
              {{ $t( 'domains.services.medical_checkups.edit' ) }}
            </pms-button>
          </template>
        </pms-smart-table>
      </div>
    </pms-grid>
  </pms-card>
</template>
<script setup lang="ts">
import { studentsHeaders, employeesHeaders, cardsHeaders, showDialog, selected } from '../values'
import { useMedicalCheckupsStore } from '../store'
import { useLoader } from '@/shared/composable'
import { storeToRefs } from 'pinia'

const { isActiveOneOf } = useLoader()
const loader = isActiveOneOf(['/service/medical-checkups/card', '/service/medical-checkups/employee', '/service/medical-checkups/student'])
const store = useMedicalCheckupsStore()

const { students, employees, cards } = storeToRefs(store)

const setUser = (item) => {
  selected.value = {
    full_name: item.full_name,
    department: item.department,
    position: item.position,
    id: item.id,
    student_id: item.student_id,
    checkups: item.checkups
  }
  showDialog.value = true
}
</script>
