<template>
  <Dialog
    v-model:visible="showDialog"
    :draggable="false"
    modal
    :header="headerTitle"
    :style="{ width: '50rem' }"
  >
    <div class="d-flex flex-column gap-3">
      <div class="align-self-end">
        <pms-button
          :class="`btn btn-sm ${addingNewCheckup && 'disabled'}`"
          @click="addCheckup"
        >
          <pms-kt-icon
            :icon-name="'plus-circle'"
            class="fw-bold fs-3 p-0"
          />
          {{ $t('domains.services.medical_checkups.add') }}
        </pms-button>
      </div>
      <pms-smart-table
        :headers="checkupsHeader"
        :items="checkups"
      >
        <template #checkup_date="{ item }">
          <DatePicker
            v-if="item.status === CheckupStatus.CREATED || item.status === CheckupStatus.EDITING"
            v-model="selectedDate"
            show-icon
            fluid
            icon-display="input"
            date-format="yy-mm-dd"
            class="form-control form-control-sm"
          />
          <span v-else>{{ item.checkup_date }}</span>
        </template>
        <template #action="{ item }">
          <pms-button
            v-if="item.is_last && item.status === CheckupStatus.CONFIRMED"
            variant="outlined"
            color="primary"
            size="sm"
            @click="item.status = CheckupStatus.EDITING; selectedDate = new Date(item.checkup_date)"
          >
            <pms-kt-icon
              :icon-name="'pencil'"
              class="fw-bold fs-3 p-0"
            />
          </pms-button>
          <pms-button
            v-if="item.status === CheckupStatus.CREATED || item.status === CheckupStatus.EDITING"
            variant="outlined"
            color="danger"
            size="sm"
            @click="cancel(item)"
          >
            <pms-kt-icon
              :icon-name="'trash'"
              class="fw-bold fs-3 p-0"
            />
            {{ $t('domains.services.medical_checkups.cancel') }}
          </pms-button>
        </template>
      </pms-smart-table>
      <div class="align-self-end">
        <pms-button
          class="btn btn-sm"
          @click="putCheckup()"
        >
          {{ $t('domains.services.medical_checkups.save') }}
        </pms-button>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { showDialog, selected, CheckupStatus, checkupsHeader } from '../values'
import { computed, ref, watch } from 'vue'
import DatePicker from 'primevue/datepicker'
import { formatCheckups } from '../utils/formatCheckups'
import { useAlert } from '@/shared/composable'
import { useMedicalCheckupsStore } from '../store'
import { PutCheckupRequest } from '../store/api/methods/putCheckup/types'

const { error, success } = useAlert()
const store = useMedicalCheckupsStore()
const headerTitle = computed(() => {
  return `${selected.value?.full_name} - ${selected.value?.student_id ? selected.value.student_id : selected.value?.position}`
})
const checkups = ref(formatCheckups(selected.value?.checkups))
const selectedDate = ref(new Date())
const addingNewCheckup = ref<boolean>(false)

const addCheckup = () => {
  addingNewCheckup.value = true
  checkups.value.push({
    checkup_date: new Date().toLocaleDateString('en-CA'),
    is_last: false,
    status: CheckupStatus.CREATED
  })
}

const putCheckup = async () => {
  const addedCheckups: PutCheckupRequest = []
  checkups.value.forEach((checkup) => {
    if ((checkup.status === CheckupStatus.CREATED || checkup.status === CheckupStatus.EDITING) && selected.value) {
      addedCheckups.push({
        user_type: selected.value?.student_id ? 'S' : 'P',
        user_id: selected.value?.student_id ? selected.value.student_id : String(selected.value.id),
        checkup_date: selectedDate.value.toLocaleDateString('en-CA')
      })
    }
  })
  try {
    addedCheckups.length && await store.putCheckupAction(addedCheckups, true)
    showDialog.value = false
    addingNewCheckup.value = false
    success({ text: 'domains.services.medical_checkup.success' })
  } catch (e) {
    error({ text: 'domains.services.medical_checkups.error.save' })
  }
}

const cancel = (checkup) => {
  if (checkup.status === CheckupStatus.CREATED) {
    checkups.value.splice(checkups.value.indexOf(checkup), 1)
    addingNewCheckup.value = false
  } else {
    checkup.status = CheckupStatus.CONFIRMED
  }
}

watch(selected, () => {
  checkups.value = formatCheckups(selected.value?.checkups)
})
</script>
