import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { getProgramGroupsResourcesResponse, getProgramGroupsResourcesRequest } from './types'
import { UnknownRequest } from '@/shared/swagger'

export default async function getProgramGroupsResources (params: getProgramGroupsResourcesRequest): Promise<AxiosResponse<getProgramGroupsResourcesResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, getProgramGroupsResourcesResponse>({
    url: 'service/program-groups/resources',
    body: { params },
    loader: 'service/program-groups/resources'
  })
  return response
}
