import type { StoreType } from '../types'
import { useAlert } from '@/shared/composable'
import getProgramGroup from '../api/methods/getProgramGroup'

export default async function fetchProgramGroup (this: StoreType, code: string): Promise<void> {
  const { error } = useAlert()
  const { data, ok, message } = await getProgramGroup(code)

  if (ok) {
    this.programGroup = data
  } else {
    error({ text: message, isToast: true })
  }
}
