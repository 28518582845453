import { useRequests } from '@/shared/composable'

import type { UnknownRequest } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'
import { AcademicManageCurriculumViewRequest, AcademicManageCurriculumViewResponse } from './types'

export default async function getAcademicManageCurriculumView (params: AcademicManageCurriculumViewRequest): Promise<AxiosResponse<AcademicManageCurriculumViewResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCurriculumViewResponse>({
    url: 'academic/manage/curriculum/view',
    body: { params },
    loader: 'academic/manage/curriculum/view'
  })

  return response
}
