import type { AxiosResponse } from '@shared/types'
import { useRequests } from '@/shared/composable'
import { UnknownRequest } from '@/shared/swagger'
import { GetMedicalCheckupsStudentsRequest, GetMedicalCheckupsStudentsResponse } from './types'

export default async function getStudents (params: GetMedicalCheckupsStudentsRequest): Promise<AxiosResponse<GetMedicalCheckupsStudentsResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, GetMedicalCheckupsStudentsResponse>({
    url: '/service/medical-checkups/student',
    body: { params },
    loader: '/service/medical-checkups/student'
  })

  return response
}
