import { RouteRecordWithLayout } from '@shared/types'

export default {
  layout: 'default',
  path: '/service/medical-checkups',
  children: [
    {
      path: '',
      name: 'MedicalCheckups',
      component: () => import('./MedicalCheckupPage.vue')
    }
  ]
} as RouteRecordWithLayout
