import { GetMedicalCheckupsStudentsResponse } from '../store/api/methods/getStudents/types'
import { CheckupStatus } from '../values'

export const formatCheckups = (checkups: GetMedicalCheckupsStudentsResponse[0]['checkups'] | undefined) => {
  if (!checkups) return []
  return checkups.map((checkup) => {
    return {
      checkup_date: checkup.checkup_date,
      is_last: checkup.is_last,
      status: CheckupStatus.CONFIRMED
    }
  })
}
