import { SmartTableHeader } from '@/shared/types'

export const surveysTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.year-terms',
    field: 'year_term',
    sortable: true,
    slotCellName: 'year-terms'
  },
  {
    title: 'common.question',
    field: 'questionquestion',
    slotCellName: 'question'
  },
  {
    title: 'common.participation',
    field: 'participation',
    slotCellName: 'participation'
  },
  {
    title: 'common.actions',
    field: 'actions',
    slotCellName: 'actions'
  }
]
