<template>
  <pms-page-toolbar
    v-bind="header"
    :go-back="{ name: 'AcademicProgramGroupsPage' }"
  />

  <div>
    <Card
      class="mt-5"
    >
      <template #content>
        <Tabs
          value="0"
        >
          <TabList class="">
            <Tab
              value="0"
            >
              {{ $t("domains.program-groups.tabs.general.title") }}
            </Tab>
            <Tab
              v-show="programGroup?.type === ProgramGroupType.CIPHER"
              value="1"
            >
              {{ $t("domains.program-groups.tabs.description.title") }}
            </Tab>
            <Tab
              v-show="programGroup?.type === ProgramGroupType.CIPHER && (programGroup?.program_type?.type === 'JD' || programGroup?.program_type?.type === 'DD')"
              value="2"
            >
              {{ $t("domains.program-groups.tabs.partner.title") }}
            </Tab>
            <Tab
              v-show="programGroup?.type === ProgramGroupType.CIPHER"
              value="3"
            >
              {{ $t("domains.program-groups.tabs.outcome.program-outcome-title") }}
            </Tab>
            <Tab
              v-show="programGroup?.type === ProgramGroupType.CIPHER"
              value="4"
            >
              {{ $t("domains.program-groups.tabs.outcome.student-outcome-title") }}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel value="0">
              <ProgramGroupInfo />
            </TabPanel>
            <TabPanel value="1">
              <DescriptionInfo />
            </TabPanel>
            <TabPanel
              value="2"
            >
              <PartnerInfo />
            </TabPanel>
            <TabPanel value="3">
              <OutcomeInfo :type="EOutcomeTypes.Program" />
            </TabPanel>
            <TabPanel value="4">
              <OutcomeInfo :type="EOutcomeTypes.Student" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </template>
    </Card>
  </div>
</template>
<script lang="ts" setup>
import Card from 'primevue/card'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'
import TabPanel from 'primevue/tabpanel'

import ProgramGroupInfo from './ui/ProgramGroupInfo/ProgramGroupInfo.vue'
import OutcomeInfo from './ui/OutcomeInfo/OutcomeInfo.vue'
import DescriptionInfo from './ui/DescriptionInfo/DescriptionInfo.vue'
import PartnerInfo from './ui/PartnerInfo/PartnerInfo.vue'
import { computed, onMounted, onUnmounted } from 'vue'
import { outcomeDefaultValues, useProgramGroupStore } from '../../store'
import { ProgramGroupModules } from '../../store/api/methods/getProgramGroupsResources/types'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { EOutcomeTypes, ProgramGroupType } from '../../store/enums'
import { currentLangShort } from '../../consts'

const store = useProgramGroupStore()
const { programGroup } = storeToRefs(store)
const route = useRoute()
const code = route.params.code.toString()

const header = computed(() => {
  const title = programGroup.value?.['title_' + currentLangShort.value]
  return {
    title: `${programGroup?.value?.code} - ` + title,
    description: 'domains.academic.advisory.description',
    tour: true
  }
})

onMounted(() => {
  // Для того чтобы при редактировании parentCode выводить tree
  store.fetchProgramGroups({})

  store.fetchProgramGroupsResources({ modules: [ProgramGroupModules.countries, ProgramGroupModules.degree_types, ProgramGroupModules.education_levels, ProgramGroupModules.program_group_types] })
  store.fetchProgramGroup(code)
  store.fetchProgramGroupOutcomes(code, { })
})

onUnmounted(() => {
  store.programGroup = null
  store.outcomes = outcomeDefaultValues
})
</script>

<style>
.p-tablist-tab-list {
  justify-content: center; /* Centers the tabs */
}
.p-tablist-active-bar {
  display: none !important;
}
</style>
