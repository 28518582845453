<template>
  <pms-modal-sidebar
    v-model:current-width="currentWidth"
    v-model:state="sideBarState"
    :title="sideBarTitle"
    :description="sidebarDescription"
    :modal="'AttendanceDetails'"
    :width="'450px'"
    :position="'end'"
    :scroll-out="false"
    :resize="true"
    :backdrop="true"
  >
    <pms-loader
      v-if="detailsLoader"
      :options="{
        body: { class: 'p-0 ps-2 pt-0 pe-2' }
      }"
      :items="6"
    />
    <template v-else>
      <div v-if="sideBarData === null">
        <pms-no-data class="mt-20 fs-4 fw-bold" />
      </div>
      <div
        v-else
        class="row"
      >
        <div
          v-for="(item, index) in (sideBarData?.items || [])"
          :key="index"
          :class="{ 'col-12': currentWidth < 1100, 'col-6': currentWidth >= 1100 }"
        >
          <template v-if="item.hours.length > 0">
            <div
              v-if="index !== 0 && currentWidth < 1100"
              class="separator separator-dashed my-4"
            />
            <div class="d-flex justify-content-between mb-4">
              <div class="fw-bold fs-5">
                {{ $t(item.title) }} ({{ item.room }})
              </div>
              <div>
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm btn-icon  me-2"
                  :class="{ 'btn-primary': !item.showTable }"
                  style="width: 2.3rem; height: 2.3rem;"
                  @click="item.showTable = !item.showTable"
                >
                  <inline-svg
                    height="1.4rem"
                    src="/media/icons/duotune/graphs/gra010.svg"
                  />
                </a>
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm btn-icon me-2"
                  :class="{ 'btn-primary': item.showTable }"
                  style="width: 2.3rem; height: 2.3rem;"
                  @click="item.showTable = !item.showTable"
                >
                  <inline-svg
                    height="1.4rem"
                    src="/media/icons/duotune/abstract/abs015.svg"
                  />
                </a>
              </div>
            </div>
            <pms-tab
              v-model:active="item.activeHour"
              :tabs="item.hourTabs"
              :icon="false"
              :all="false"
            >
              <template
                v-for="hour in item.hours"
                :key="hour.hour"
                #[`${hour.hour}`]
              >
                <div v-if="!item.showTable">
                  <apexchart
                    class="mixed-widget-4-chart"
                    type="donut"
                    height="400"
                    :options="{
                      ...sideBarDynamicsOptions,
                      labels: [
                        $t('domains.academic.single-student.attendance.statuses.present'),
                        $t('domains.academic.single-student.attendance.statuses.absent'),
                        $t('domains.academic.single-student.attendance.statuses.permitted'),
                        $t('domains.academic.single-student.attendance.statuses.not_tracked')
                      ]
                    }"
                    :series="hour.series"
                  />
                </div>
                <pms-smart-table
                  v-if="item.showTable"
                  :headers="detailsTableHeader"
                  :items="hour.data || []"
                >
                  <template #item-no="{ idx }">
                    <span class="fs-5"> {{ idx + 1 }}</span>
                  </template>
                  <template #date="{ value }">
                    <span class="fs-5">{{ dateFormatter(value, dateOptions) }}</span>
                  </template>
                  <template #item-track="{ value }">
                    <i
                      v-if="value"
                      class="fs-2 bi"
                      :class="{
                        'bi-check-circle text-primary': value.attend === 1,
                        'bi-p-circle text-info': value.attend === 2,
                        'bi-x-circle text-danger': value.attend === 0,
                        'bi-circle text-secondary': value.attend === -1
                      }"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-kt-initialized="1"
                      :data-bs-original-title="$t('domains.academic.single-student.attendance.statuses.' + value.attend)"
                    />
                  </template>
                </pms-smart-table>
              </template>
            </pms-tab>
          </template>
        </div>
      </div>
    </template>
  </pms-modal-sidebar>
</template>
<script setup lang="ts">
import { PropType } from 'vue'

import { useLoader } from '@shared/composable'
import { dateFormatter } from '@shared/utils'

import { sideBarTitle, sideBarState, sidebarDescription, currentWidth, sideBarDynamicsOptions, detailsTableHeader } from '../values'
import { AttendanceDetailsGraph } from '../../../types'
import { dateOptions } from '../../../values'

defineProps({
  sideBarData: {
    type: Object as PropType<AttendanceDetailsGraph | null>,
    required: true
  }
})

const { isActive } = useLoader()

const detailsLoader = isActive('academic/students/attendance/details')
</script>
<style>
.mixed-widget-4-chart:deep(.apexcharts-tooltip) {
  background-color: red;
}
</style>
