<template>
  <Dialog
    v-model:visible="uploadExcelModalVisible"
    :draggable="false"
    :header="`${$t('domains.services.medical_checkups.upload_excel')}`"
    :style="{ width: '50rem' }"
  >
    <div class="d-flex flex-column gap-3">
      <div class="d-flex gap-3">
        <Message
          severity="error"
          class="fs-4"
          variant="outlined"
        >
          {{ $t('domains.services.medical_checkup.rule') }}
        </Message>
        <pms-button
          color="primary"
          variant="outlined"
          size="sm"
          @click="downloadTemplate()"
        >
          {{ $t('common.dowmload-template') }}
        </pms-button>
      </div>
      <FileUpload
        name="demo[]"
        accept=".xls,.xlsx"
        :auto="false"
        :max-file-size="1000000"
        @select="onSelectedFiles"
      >
        <template #header="{ chooseCallback }">
          <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
            <div class="flex gap-2">
              <pms-button
                color="primary"
                variant="outlined"
                size="sm"
                @click="chooseCallback()"
              >
                {{ $t('common.choose-from-files') }}
              </pms-button>
            </div>
          </div>
        </template>
        <template #content="{ files }">
          <div
            v-if="files.length > 0"
            class="d-flex flex-row align-items-center justify-content-center flex-wrap"
          >
            <div
              v-for="(file, index) in files"
              :key="file.name"
              class="border border-2 gap-3 d-flex flex-column align-items-center justify-content-center p-5"
            >
              <i class="bi bi-file-earmark-excel-fill text-success fs-1" />
              <span>{{ file.name }}</span>
              <span>({{ formatSize(file.size) }})</span>
              <pms-button
                variant="outlined"
                color="danger"
                size="sm"
                @click="onRemoveTemplatingFile(index)"
              >
                <pms-kt-icon
                  :icon-name="'trash'"
                  class="fw-bold fs-3 p-0"
                />
              </pms-button>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <i class="bi bi-cloud-arrow-up upload-icon" />
            <span class="h5 mt-3 mb-0">
              {{ $t('domains.services.medical_checkup.drag-and-drop') }}
            </span>
          </div>
        </template>
      </FileUpload>
      <pms-button
        color="primary"
        size="sm"
        class="align-self-end"
        @click="readExcelFile()"
      >
        {{ $t('common.upload') }}
      </pms-button>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import FileUpload from 'primevue/fileupload'
import Dialog from 'primevue/dialog'
import { ref } from 'vue'
import * as XLSX from 'xlsx'
import { uploadExcelModalVisible } from '../values'
import { useMedicalCheckupsStore } from '../store'
import { useAlert } from '@/shared/composable'
import Message from 'primevue/message'
import { downloadTemplate } from '../utils'

const files = ref<File[]>([])
const excelData = ref<any[]>([])
const store = useMedicalCheckupsStore()
const { error } = useAlert()

const formatSize = (bytes: number): string => {
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`
}

const onSelectedFiles = (event: { files: File[] }) => {
  files.value = event.files
}

const onRemoveTemplatingFile = (index) => {
  files.value.splice(index, 1)
}

const readExcelFile = async () => {
  files.value.forEach(file => {
    const reader = new FileReader()

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const binaryData = e.target?.result
      const workbook = XLSX.read(binaryData, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      excelData.value = XLSX.utils.sheet_to_json(sheet)

      const excelDateToJSDate = (serial: number): Date => {
        const excelBaseDate = new Date(Date.UTC(1899, 11, 30))
        const utcDate = new Date(excelBaseDate.getTime() + serial * 24 * 60 * 60 * 1000)
        return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate())
      }

      const values = excelData.value.map((data) => {
        return {
          user_type: 'S',
          user_id: String(data.id),
          checkup_date: excelDateToJSDate(data.date).toLocaleDateString('en-CA')
        }
      })

      try {
        await store.putCheckupAction(values, false)
        uploadExcelModalVisible.value = false
      } catch (e) {
        error({ text: 'domains.services.medical_checkups.error.save' })
      }
    }

    reader.readAsBinaryString(file)
  })
}
</script>
<style scoped>
.upload-icon {
  font-size: 5rem;
  cursor: pointer;
}
</style>
