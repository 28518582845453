import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { UnknownRequest, UnknownResponse } from '@/shared/swagger'

export default async function deleteProgramGroupOutcome (code: string, outcomeId: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<UnknownRequest, UnknownResponse>({
    url: `service/program-groups/${code}/outcomes/${outcomeId}`,
    body: {},
    loader: 'delete-service/program-groups/outcomes'
  })
  return response
}
