import { useAlert } from '@/shared/composable'
import { StoreType } from '../types'
import { getProgramGroupsResourcesRequest } from '../api/methods/getProgramGroupsResources/types'
import getProgramGroupsResources from '../api/methods/getProgramGroupsResources'

export default async function fetchProgramGroupsResources (this: StoreType, params: getProgramGroupsResourcesRequest): Promise<void> {
  const { error } = useAlert()
  const { data, ok, message } = await getProgramGroupsResources(params)

  if (ok) {
    this.resources = data
  } else {
    error({ text: message, isToast: true })
  }
}
