import type { AxiosResponse } from '@shared/types'
import { useRequests } from '@/shared/composable'
import { UnknownRequest } from '@/shared/swagger'
import { GetMedicalCheckupsEmployeesRequest, GetMedicalCheckupsEmployeesResponse } from './types'

export default async function getEmployees (params: GetMedicalCheckupsEmployeesRequest): Promise<AxiosResponse<GetMedicalCheckupsEmployeesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, GetMedicalCheckupsEmployeesResponse>({
    url: '/service/medical-checkups/employee',
    body: { params },
    loader: '/service/medical-checkups/employee'
  })

  return response
}
