import { useStore } from '@/domains/AcademicCurricula/store'
import { AcademicManageCurriculumViewResponse } from '@/domains/AcademicCurricula/store/api/methods/getCurriculumView/types'
import editCoreCourseBulk from '@/shared/api/AcademicCurricula/editCoreCourseBulk'
import { useAlert } from '@/shared/composable'
import { useResource } from '@/shared/stores'
import { AcademicManageCurriculumCorePutBulkRequest } from '@/shared/swagger'
import { FormField, SelectField } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const useCoreEditor = (electiveType: string) => {
  const resource = useResource()
  const { t } = useI18n()
  const { error, success } = useAlert()
  const store = useStore()
  const { curricula } = storeToRefs(store)
  const { academicManageCurriculumResource } = storeToRefs(resource)

  const selectedCourses = computed<AcademicManageCurriculumViewResponse['electives']['area']>(() => curricula.value?.electives[electiveType].filter(item => item._isSelected))

  const showDialog = ref(false)
  const handleShowDialog = () => {
    showDialog.value = true
  }
  const handleHideDialog = () => {
    showDialog.value = false
  }

  const route = useRoute()

  const formItemClasses = 'formItem me-5'

  const courseSettings = ref<{
      term: null | number,
      type: null | string,
      electiveType: null | string,
      is_active: null | boolean,
      trackCode: null | string
    }>({
      term: selectedCourses.value[0].term,
      type: selectedCourses.value[0].type,
      electiveType: selectedCourses.value[0].elective,
      is_active: selectedCourses.value[0].is_active,
      trackCode: selectedCourses.value[0].track
    })

  const terms = ref<number[]>([])

  const electiveTypesOptions = ref<SelectField['options']>([])
  const coreTypesOptions = ref<SelectField['options']>([])
  const trackOptions = ref<SelectField['options']>([])

  watch(() => curricula, () => {
    if (curricula.value) {
      terms.value = Array.from({ length: curricula.value.information.terms }, (_, i) => i + 1)
    }
  }, { immediate: true })

  watch(() => academicManageCurriculumResource, () => {
    if (academicManageCurriculumResource.value) {
      electiveTypesOptions.value = academicManageCurriculumResource.value.elective_names.map((electiveName) => {
        return {
          label: electiveName.title,
          value: electiveName.code
        }
      })

      coreTypesOptions.value = academicManageCurriculumResource.value.core_types.map((coreType) => {
        return {
          label: coreType.title,
          value: coreType.key
        }
      })

      trackOptions.value = academicManageCurriculumResource.value.tracks.map((track) => {
        return {
          label: track.title,
          value: track.key
        }
      })
    }
  }, { immediate: true })

  const courseSettingsFormFields = computed<Array<FormField>>(() => [
    {
      label: {
        text: t('common.is_active'),
        class: 'mb-1'
      },
      name: 'is_active',
      value: courseSettings.value.is_active || true,
      type: 'switch',
      group: {
        class: formItemClasses
      }
    },
    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },
    {
      label: {
        text: t('common.type'),
        class: 'mb-1'
      },
      name: 'type',
      type: 'select',
      value: courseSettings.value.type || null,
      options: coreTypesOptions.value,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.term'),
        class: 'mb-1'
      },
      name: 'term',
      type: 'select',
      value: courseSettings.value.term || null,
      options: terms.value.map((term) => {
        return {
          label: term.toString(),
          value: term
        }
      }),
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.group'),
        class: 'mb-1'
      },
      name: 'electiveType',
      type: 'select',
      value: courseSettings.value.electiveType,
      options: electiveTypesOptions.value,
      group: {
        class: formItemClasses
      }
    },

    {
      label: {
        text: t('common.track'),
        class: 'mb-1'
      },
      name: 'trackCode',
      type: 'select',
      value: courseSettings.value.trackCode,
      options: trackOptions.value,
      group: {
        class: formItemClasses
      }
    }
  ]
  )

  const onSubmit = async () => {
    if (!curricula.value) return

    const ids = selectedCourses.value.map(item => item.id)

    if (!courseSettings.value.trackCode) {
      error({
        text: t('domains.academic.curricula.core-edit.track-not-selected-error')
      })
      return
    }
    if (!courseSettings.value.type) {
      error({
        text: t('domains.academic.curricula.core-edit.type-not-selected-error')
      })
      return
    }
    if (courseSettings.value.is_active === null) {
      error({
        text: t('domains.academic.curricula.core-edit.is_active-not-selected-error')
      })
      return
    }

    if (courseSettings.value.term === null) {
      error({
        text: t('domains.academic.curricula.core-edit.term-selected-error')
      })
      return
    }

    const requestData: AcademicManageCurriculumCorePutBulkRequest = {
      type: courseSettings.value.type,
      term: courseSettings.value.term,
      is_active: courseSettings.value.is_active,
      track_code: courseSettings.value.trackCode,
      ids
    }
    if (courseSettings.value.term) {
      requestData.term = courseSettings.value.term
    }

    if (courseSettings.value.electiveType) {
      requestData.elective_type = courseSettings.value.electiveType
    }

    const { status, message } = await editCoreCourseBulk(requestData)

    if (status) {
      success({ text: message })
      handleHideDialog()

      if (route.query.track) store.fetchCurricula({ track: +route.query.track })
    } else {
      error({ text: message })
    }
  }

  return {
    courseSettings,
    courseSettingsFormFields,
    selectedCourses,
    onSubmit,
    showDialog,
    handleShowDialog,
    handleHideDialog
  }
}

export default useCoreEditor
